import { Component } from 'react';
// import Image from '../../components/Image/Image';
import { Col, Row } from 'react-bootstrap';
import {
  GoogleMap,
  InfoWindow,
  Marker,
  withGoogleMap,
  withScriptjs,
} from 'react-google-maps';
import { useHistory, useParams } from 'react-router';
import { compose } from 'recompose';
import * as CONSTANT from 'src/constants';
import { awsAssetsFetcher } from 'src/utils/awsAssetsFetcher';

import styleClasses from '../location.module.scss';
import LocationMarker from '../LocationMarker';
const MapWithAMarker = compose(
  withScriptjs,
  withGoogleMap,
)((props) => {
  const history = useHistory();
  const { id: locationName } = useParams();
  let id = decodeURIComponent(locationName);

  const exampleMapStyles = [
    {
      featureType: 'poi',
      elementType: 'geometry',
      stylers: [
        {
          color: '#eeeeee',
        },
      ],
    },
    {
      featureType: 'poi',
      elementType: 'labels.text',
      stylers: [
        {
          visibility: 'off',
        },
      ],
    },
    {
      featureType: 'water',
      elementType: 'labels.text.fill',
      stylers: [
        {
          color: '#9e9e9e',
        },
      ],
    },
  ];
  const markers = () => {
    const singleMarker = history.location.pathname.includes('locationcopy');
    if (!singleMarker) return props.markers;
    else {
      return props.markers.filter((item) => item.name == id);
    }
  };

  const bounds = new window.google.maps.LatLngBounds();
  if (props?.markers?.length > 2) {
    props.markers.map((model) => {
      const { latitude, longitude } = model;
      const latLng = new window.google.maps.LatLng(latitude, longitude);
      bounds.extend(latLng);
    });
  }

  return (
    <GoogleMap
      ref={
        props?.markers?.length > 2
          ? (map) => map && map.fitBounds(bounds)
          : null
      }
      defaultZoom={props.markers[0] !== undefined ? 8 : 4}
      defaultCenter={{
        lat:
          props.markers[0] !== undefined
            ? props.markers[0].latitude
            : 38.51675742862462,
        lng:
          props.markers[0] !== undefined
            ? props.markers[0].longitude
            : -97.476975969198,
      }}
      options={{
        zoomControl: true,
      }}
      fitBounds
    >
      {markers().map((marker) => {
        const onClick = props.onClick.bind(this, marker);
        const onMouseOut = props.onMouseOut.bind(this, marker);
        const onLocationClick = props.onLocationClick.bind(this, marker);
        const onCloseClick = props.onCloseClick.bind(this, marker);
        let iconMarker = new window.google.maps.MarkerImage(
          awsAssetsFetcher('Locations/Location_markup_green', 'svg'),
          null /* size is determined at runtime */,
          null /* origin is 0,0 */,
          null /* anchor is bottom center of the scaled image */,
          new window.google.maps.Size(25, 30),
        );

        return (
          <Marker
            icon={iconMarker}
            key={marker.id + '_' + marker.latitude}
            onMouseOver={onClick}
            onMouseOut={onMouseOut}
            onClick={onLocationClick}
            position={{ lat: marker.latitude, lng: marker.longitude }}
          >
            {props.selectedMarker == marker && props.showMapPopUp == true && (
              <InfoWindow onCloseClick={onCloseClick}>
                <div className="popup_container">
                  <LocationMarker />
                  <div
                    className={`${styleClasses.map_popup_description_container} text-start`}
                  >
                    <div className={styleClasses.location_name}>
                      {marker.data.name}
                    </div>
                    <div
                      className={`${styleClasses.location_address} ${styleClasses.location_address_popup}`}
                    >
                      {marker.data.address}
                    </div>
                    <Row>
                      <Col md={12}>
                        <div className={styleClasses.location_phone_number}>
                          {marker.data.phone}
                        </div>
                        {marker.data.mon_thurs_timings && (
                          <div
                            className={`${styleClasses.timming_box} ${styleClasses.timing_box_popup}`}
                          >
                            <div className={styleClasses.location_phone_number}>
                              Mon - Thu
                            </div>
                            <div className={styleClasses.location_phone_number}>
                              {marker.data.mon_thurs_timings}
                            </div>
                          </div>
                        )}
                        {marker.data.fri_sat_timings && (
                          <div
                            className={`${styleClasses.timming_box} ${styleClasses.timing_box_popup}`}
                          >
                            <div className={styleClasses.location_phone_number}>
                              Fri - Sat
                            </div>
                            <div className={styleClasses.location_phone_number}>
                              {marker.data.fri_sat_timings}
                            </div>
                          </div>
                        )}
                        <div className="d-flex align-items-end">
                          {marker.data.sunday_timings && (
                            <div
                              className={`${styleClasses.timming_box} ${styleClasses.timing_box_popup}`}
                            >
                              <div
                                className={styleClasses.location_phone_number}
                              >
                                Sunday
                              </div>
                              <div
                                className={styleClasses.location_phone_number}
                              >
                                {marker.data.sunday_timings}
                              </div>
                            </div>
                          )}
                          <div
                            className={`${styleClasses.order_btn_wrap} text-end flex-fill`}
                          >
                            {marker.order_type == 'small_order' ? (
                              <a
                                href={
                                  marker.data.olo_individual_order_url &&
                                  marker.data.olo_individual_order_url
                                }
                                className={`${styleClasses.order_button} menu-section`}
                              >
                                Order Now
                              </a>
                            ) : marker.zipParamsCode ? (
                              <a
                                href={
                                  marker.data.olo_take_out_order_url &&
                                  marker.data.olo_take_out_order_url
                                }
                                className={`${styleClasses.order_button} menu-section`}
                              >
                                Order Now
                              </a>
                            ) : (
                              <a
                                href={
                                  marker.data.olo_individual_order_url &&
                                  marker.data.olo_individual_order_url
                                }
                                className={`${styleClasses.order_button} menu-section`}
                              >
                                Order Now
                              </a>
                            )}
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </div>
              </InfoWindow>
            )}
          </Marker>
        );
      })}
    </GoogleMap>
  );
});

export default class ShelterMap extends Component {
  constructor(props) {
    super(props);
    this.state = {
      shelters: props.lat_longs,
      selectedMarker: false,
      hoveredMarker: false,
    };
  }

  componentDidMount() {
    // fetch("https://api.harveyneeds.org/api/v1/shelters?limit=20")
    //   .then(r => r.json())
    //   .then(data => {
    //     this.setState({ shelters: data.shelters })
    //   })
  }
  handleClick = (marker) => {
    if (location.pathname.includes('locationcopy')) {
      return;
    }
    this.props.setShowInfo(false);
    this.props.openedMarker(marker);
    this.setState({ selectedMarker: marker });
    this.props.setShowMapPopUp(true);
  };

  handleLocationClick = (marker) => {
    if (location.pathname.includes('locationcopy')) {
      return;
    }
    if (this.props?.markerNotClickAble) return;
    this.props.openedMarker(marker);
    this.setState({ selectedMarker: marker });
    this.getInfo(marker.data.id, marker.data.name);
  };
  handleMouseOver = (marker) => {
    this.setState({ hoveredMarker: marker });
    this.props.openedMarker(marker);
  };

  getInfo = (id, name) => {
    this.props.history.push(`${CONSTANT.ROUTE_LOCATION_COPY}/info/${name}`);
    this.props.setShowInfo(true);
  };

  handleClosePopup = (marker, event) => {
    this.setState({ selectedMarker: false });
  };
  handleMouseOverOut = (event, marker) => {
    this.props.onMouseOut(event, marker);
    this.setState({ hoveredMarker: false });
  };
  render() {
    return (
      <MapWithAMarker
        selectedMarker={this.state.selectedMarker}
        hoveredMarker={this.state.hoveredMarker}
        showMapPopUp={this.props.showMapPopUp}
        markers={this.state.shelters}
        onLocationClick={this.handleLocationClick}
        onClick={this.handleClick}
        onMouseOver={this.handleMouseOver}
        onCloseClick={this.handleClosePopup}
        onMouseOut={this.handleMouseOverOut}
        googleMapURL={`https://maps.googleapis.com/maps/api/js?v=3.exp&key=${CONSTANT.GOOGLE_API_KEY}&libraries=geometry,drawing,places`}
        loadingElement={<div style={{ height: `100%` }} />}
        containerElement={<div style={{ height: `100%` }} />}
        mapElement={<div style={{ height: `100%`, width: `100%` }} />}
      />
    );
  }
}
