import React, { useEffect, useRef, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useQueryClient } from 'react-query';
import { useLocation } from 'react-router';
import { ROUTE_CHECKOUT, s3BaseUrl } from 'src/constants';
import {
  IMarketListingsQueryParams,
  LocationModel,
} from '../../../models/location.model';
import { resetOrderDetail } from '../../../redux/slices/cartSlice';
import { useAppDispatch } from '../../../redux/store/store';
import { locationMenuService } from '../../../services';
import styleClassesLocationModule from './Location.module.scss';
// import MapsRender from '../../location/MapsRender';
// import NoLocation from '../../location/NoLocation';
// import ShelterMap from '../../location/maps';
import ShelterMap from 'src/Features/Location/Maps';
import MapsRender from 'src/Features/Location/MapsRender';
import NearByLocations from 'src/Features/Location/NearByLocations';
import { setLocation } from 'src/Features/Location/redux/slice';
import { useAppSelector } from 'src/redux/store/store';
import styleClasses from '../order.module.scss';
import SearchLocation from './subcomponents/SearchLocation';
import SearchedLocationsCard from './subcomponents/SearchedLocationsCard';
import locationMarker from '../../../assets/images/Svgs/locationMarker.svg';
interface IChangeStoreLocationProps {
  location?: any;
  history?: any;
  borderNone?: string;
  closeModal?: () => void;
}
const ChangeLocation = (props: IChangeStoreLocationProps) => {
  const { location, history, borderNone, closeModal } = props;
  const locationDispatch = useAppDispatch();

  const [zipCode, setZipCode] = React.useState<string>('');
  const [zipCodeCopy, setZipCodeCopy] = React.useState<string>('');
  const [showInfo, setShowInfo] = React.useState<boolean>(true);
  const [locationNotFound, setLocationNotFound] =
    React.useState<boolean>(false);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [locations, setLocations] = useState<LocationModel[]>([]);
  const [selectedLocation, setSelectedLocations] =
    useState<LocationModel>(null);
  const [isLocationLoading, setLocationLoading] = useState<boolean>(false);
  const [isNoneDisplay, setNoneDisplay] = useState<boolean>(false);
  const [lat_longs, setLatLongs] = React.useState<any>([]);
  const [order_type, setOrderType] = React.useState<string>('');
  const [zipParamsCode, setZipParamsCode] = React.useState<string>();
  const [hovered, setHovered] = React.useState<any>(null);
  const [isDeviceIos, setIsDeviceIos] = React.useState(false);
  const [showMapPopUp, setShowMapPopUp] = React.useState<boolean>(false);
  const [markets, setMarkets] = React.useState<any>();
  const [marketsLoading, setMarketsLoading] = React.useState<boolean>(false);

  const { user: authInfo } = useAppSelector((state) => state.user);
  const dispatch = useAppDispatch();
  const queryClient = useQueryClient();

  const myRefs = useRef([]);
  const myRef = useRef(null);
  const myListRef = useRef(null);
  const scrollRef = useRef(null);

  const { pathname } = useLocation();
  const isCheckout = pathname.includes(ROUTE_CHECKOUT);
  const executeScroll = () =>
    myRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });

  useEffect(() => {
    fetchStates();
  }, []);

  useEffect(() => {
    var userAgent = navigator.userAgent || navigator.vendor;
    if (/iPad|iPhone|iPod|Mac/.test(userAgent) && !window.MSStream) {
      setIsDeviceIos(true);
    } else {
      setIsDeviceIos(false);
    }
    setLoading(false);
    onHistoryChange();
    // setLoading(true);
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);

    window.addEventListener('resize', () => {
      let vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    });
  }, [history]);

  //used "useEffect" to handle scroll on location search.
  useEffect(() => {
    if (
      locations &&
      locations.length > 0 &&
      myRef.current &&
      !isLocationLoading
    ) {
      executeScroll();
    } else if (
      styleClasses.map_ctn_wrap_class &&
      (locationNotFound || isNoneDisplay) &&
      scrollRef.current
    ) {
      executeNoLocScroll();
    }
  }, [locations, isLocationLoading, locationNotFound, isNoneDisplay]);

  const handleSearchChange = (searchValue) => {
    setZipCode(searchValue);
    handleEnter(searchValue);
  };

  const handleEnter = (searchValue) => {
    handlesearchClick(searchValue);
  };

  const getNearbyLocations = async (payload: any) => {
    if (authInfo.id) payload.user_id = authInfo.id;
    if (isCheckout) payload.is_checkout = 1;
    return await locationMenuService.getNearbyLocations(payload);
  };

  const handleMouseOverBox = (marker) => {
    setHovered(marker);
  };

  const handleScroll = () => {
    document.documentElement.scrollTop = 0;
    myListRef.current.scrollTop = 0;
  };

  const executeNoLocScroll = () => {
    let extraDiv = document.querySelector(
      '.' + styleClasses.map_ctn_wrap + ' > div > div',
    );
    if (extraDiv && extraDiv.children[1]) {
      extraDiv.children[1].remove();
    }
    scrollRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
  };

  const removeSearch = () => {
    setZipCode('');
    setZipCodeCopy('');
    setLocations([]);
    setLocationLoading(false);
    setNoneDisplay(true);
    setLatLongs([]);
  };

  const searchLocation = async (search) => {
    const array = [];
    const response = await getNearbyLocations({ address: search });
    setLocationNotFound(false);
    setLoading(false);
    const data = response.data.data;
    data.map(function (data) {
      array.push({
        latitude: data.lat,
        longitude: data.long,
        name: data.name,
        data: data,
        order_type: order_type,
        zip_code_param: zipParamsCode,
      });
    });
    return { response, data, array };
  };

  const filterSearchedData = async (search: string) => {
    setSelectedLocations(null);
    if (search.length > 3) {
      const { response, data, array } = await searchLocation(search);
      setLatLongs(array);
      if (typeof response.data === 'undefined' || data.length == 0) {
        setLocations([]);
        setLocationLoading(false);
        setNoneDisplay(true);
      } else {
        setLocations(data);
        setLocationLoading(false);
        setNoneDisplay(false);
      }
    } else {
      setLocations([]);
      setLocationLoading(false);
      setNoneDisplay(true);
    }
  };

  // const processChange = debounce(() => saveInput());

  //? Long Funciton's
  const handlesearchClick = async (searchValue) => {
    try {
      setLocationNotFound(false);
      if (searchValue === '') return;
      setNoneDisplay(false);
      setLocationLoading(true);
      setLocations([]);
      setLatLongs([]);
      let search = searchValue;
      setZipCode(search);
      setZipCodeCopy(search);
      filterSearchedData(search);
    } catch (error) {
      setLoading(false);

      setLocationLoading(false);
      setNoneDisplay(true);
    }
  };

  const handleCurrentLocation = async () => {
    try {
      setLocationLoading(true);
      setLocationNotFound(false);
      navigator.geolocation.getCurrentPosition(async function (position) {
        let array = [];
        const response = await getNearbyLocations({
          lat: position.coords.latitude,
          long: position.coords.longitude,
        });
        setLoading(false);
        response.data.data &&
          response.data.data.map(function (data) {
            if (
              typeof data.lat !== 'undefined' &&
              data.lat !== null &&
              typeof data.long !== 'undefined' &&
              data.long !== null
            ) {
              array.push({
                latitude: data.lat,
                longitude: data.long,
                name: 'abc',
                data: data,
                order_type: order_type,
                zip_code_param: zipParamsCode,
              });
            }
          });
        setLatLongs(array);
        setLocations(response.data.data);
        setLocationLoading(false);
        if (response.data.data.length == 0) {
          setNoneDisplay(true);
        }
      });
    } catch (error) {
      setLocationNotFound(true);
      setLoading(false);

      setLocationLoading(false);
      setNoneDisplay(true);
    }
  };

  const onHistoryChange = async () => {
    try {
      const query = new URLSearchParams(window.location.search);
      var order_type = query.get('order_type');
      setOrderType(order_type);
      var zip_code = query.get('postal_code');
      if (zip_code) {
        setZipParamsCode(zip_code);
        setZipCode(zip_code);
        let array = [];
        const response = await getNearbyLocations({
          address: zip_code,
        });
        setLocationNotFound(false);
        setLoading(false);
        response.data.data &&
          response.data.data.map(function (data) {
            array.push({
              latitude: data.lat,
              longitude: data.long,
              name: data.name,
              data: data,
              order_type: order_type,
              zip_code_param: zipParamsCode,
            });
          });
        setLatLongs(array);
        setLocations(response.data.data);
        setLocationLoading(false);
        if (response.data.data.length == 0) {
          setNoneDisplay(true);
        }
      }
    } catch (error) {
      setLoading(false);

      setLocationLoading(false);
      setNoneDisplay(true);
    }
  };

  const openedMarker = (marker) => {
    setHovered(marker.data.id);
    if (
      myRefs !== null ||
      myRefs.current !== null ||
      myRefs.current.length > 0
    ) {
      let scroll = myRefs.current[marker.data.id].offsetTop;
      scroll = scroll - 200;
      document.querySelector('#scroll2').scrollTo(0, scroll);
      if (typeof globalThis.bodyscroll !== 'undefined') {
        globalThis.bodyscroll.setPosition(0, scroll);
      }
    }
  };

  const fetchStates = async () => {
    try {
      setMarketsLoading(true);
      const payload: IMarketListingsQueryParams = {};
      if (isCheckout) payload.is_checkout = 1;
      const response = await locationMenuService.getMarketListings(payload);
      if (response.data) setMarkets(response.data.data.data);
      setMarketsLoading(false);
    } catch (error) {
      setMarketsLoading(false);
    }
  };

  const handleMarkets = (id) => {
    const found = markets.find((market) => market.id === id);
    setZipCode(found.name);
    let array = [];
    found.locations.map(function (data) {
      array.push({
        latitude: data.lat,
        longitude: data.long,
        name: data.name,
        data: data,
        order_type: order_type,
        zip_code_param: zipParamsCode,
      });
    });
    setLatLongs(array);
    setLocations(found.locations);
    setNoneDisplay(false);
    setLocationNotFound(false);
  };

  const onMouseOut = (event, marker) => {
    setHovered(null);
  };

  const handleSelectedLocation = (selectedLocation: LocationModel) => {
    setSelectedLocations(selectedLocation);
  };

  const continueClickHandler = () => {
    locationDispatch(setLocation(selectedLocation));
    queryClient.refetchQueries(['get-cart', selectedLocation.id]);
    dispatch(resetOrderDetail());
    closeModal();
  };

  const isContinueDisabled = () => {
    return selectedLocation ? false : true;
  };

  return (
    <div className={`${styleClasses.change_location_wrapper}`}>
      <Col md="12" lg="6" xxl="6">
        <SearchLocation
          styleClasses={styleClasses}
          searchLocationProps={{
            myListRef,
            showInfo,
            handleSearchChange,
            handleEnter,
            isNoneDisplay,
            locationNotFound,
            isLocationLoading,
            handleCurrentLocation,
            removeSearch,
          }}
          locationRenderProps={{
            locations,
            myRefs,
            handleMouseOverBox,
            hovered,
            styleClasses,
            myRef,
            zipCode,
            handleScroll,
            isDeviceIos,
            zipCodeCopy,
          }}
          noLocationProps={{ scrollRef, markets, handleMarkets }}
        />
      </Col>

      <Row>
        <Col md="12" lg="6" xxl="6">
          <SearchedLocationsCard
            styleClasses={styleClasses}
            location={locations}
            isDeviceIos={isDeviceIos}
            isLocationLoading={isLocationLoading}
            myRefs={myRefs}
            hovered={hovered}
            handleSelectedLocation={handleSelectedLocation}
            currentSelectedLocation={selectedLocation}
          />
          {(isNoneDisplay && zipCode !== '') || locationNotFound ? (
            //markets && markets.length > 0  ?
            <NearByLocations
              zipCode={zipCodeCopy}
              markets={markets}
              handleMarkets={handleMarkets}
              borderNone={null}
            />
          ) : (
            ''
          )}
        </Col>
        <Col md="12" lg="6" xxl="6">
          <div
            className={`${styleClasses.change_location_map_wrapper} mb-4 mb-md-5`}
          >
            {locations?.length > 0 ? (
              <div
                className={`${styleClassesLocationModule.map_container_wrap} w-100`}
              >
                <ShelterMap
                  lat_longs={lat_longs}
                  openedMarker={openedMarker}
                  history={history}
                  showInfo={showInfo}
                  setShowInfo={setShowInfo}
                  setShowMapPopUp={setShowMapPopUp}
                  markerNotClickAble={true}
                  onMouseOut={onMouseOut}
                ></ShelterMap>
                <div
                  className={`${styleClassesLocationModule.map_buttons_wrap} d-flex flex-column`}
                >
                  <button
                    type="button"
                    className={`${styleClassesLocationModule.current_location_btn} btn btn-link`}
                    onClick={(e) => handleCurrentLocation()}
                  >
                    <img
                      src={locationMarker}
                      className="img-fluid"
                      alt="icon"
                    />{' '}
                    Location
                  </button>
                </div>
              </div>
            ) : null}
            {locations.length === 0 && (
              <MapsRender
                styleClasses={styleClassesLocationModule}
                lat_longs={lat_longs}
                handleCurrentLocation={handleCurrentLocation}
              />
            )}
          </div>
        </Col>
      </Row>
      <hr className="custom_hr_sty mt-3 mb-4 " />
      <div className="d-flex justify-content-center">
        <button
          type="submit"
          className={` btn-large py-2 my-3 f-s16 ${
            isContinueDisabled() && 'btn-disabled-grey'
          }`}
          disabled={isContinueDisabled()}
          onClick={continueClickHandler}
        >
          Continue
        </button>
      </div>
    </div>
  );
};

export default ChangeLocation;

{
  /* <GoogleMapReact
bootstrapURLKeys={{ key: GOOGLE_API_KEY }}
defaultCenter={{ lat: 59.95, lng: 30.33 }}
defaultZoom={11}
></GoogleMapReact> */
}
