import { MutableRefObject, useEffect, useRef, useState } from 'react';
import React from 'react';
import { Container } from 'react-bootstrap';
import { useQueryClient } from 'react-query';
import { useSelector } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router';
import _clonedeep from 'lodash.clonedeep';
import CustomModal from 'src/components/customModal/CustomModal';
import Loader from 'src/components/Loader/Loader';
import * as CONSTANT from 'src/constants';
import ItemDetail from 'src/containers/ItemDetail/itemDetail';
import ItemCustomizationModal from 'src/Features/ItemCustomization/ItemCustomizationModal';
import ItemInfoModal from 'src/Features/Menu/ItemDetail/ItemInfoModal';
import {
  availableModifiers,
  createPayloadToSync,
  modifierCode,
  processEditItemModifiers,
  sortBasedOnPriceByModifierGroups,
  unavailableModifiers,
  verifyStatus,
} from 'src/helper/customizedItem/customizedItem.helper';
import { getVisitorId, isItCreateYourOwnItem } from 'src/helper/helperMethods';
import { Toast_Func } from 'src/helper/toast.helper';
import useAddToOrder from 'src/hooks/useAddToOrder';
import useCheckMobileScreen from 'src/hooks/useCheckMobileScreen';
import { cartItem } from 'src/models/cart.model';
import {
  ISubCategories,
  ItemDetailForm,
  ItemDetails,
  mapSizesKey,
} from 'src/models/item.model';
import { IAddToCartPayload, IItemAsModifiers } from 'src/models/order';
import { IGetItemFromCartPayload } from 'src/models/order.model';
import { itemBuckets } from 'src/priceCalculation/buckets';
import { calculatePriceForItem } from 'src/priceCalculation/calculatePrice';
import {
  addAdditionalPrice,
  byDefaultSeletedItems,
  calculateAdditionalPriceForItemsAsModifiers,
  calculateAndUpdateTotalAdditionalPrice,
  modifiersAgainstAnItem,
} from 'src/priceCalculation/helper';
import useGetCartItem from 'src/react-query-hooks/Cart/useGetCartItem';
import useItem from 'src/react-query-hooks/useItem';
import {
  addItem,
  addItemAsModifiersPriceInTotalPrice,
  addPackagesItem,
  editItem,
  editComboItems,
  removeSpecificItem,
  removeTemporarilyAddedPriceForItem,
  resetContext,
  resetModifiers,
  try2ComboItemSelection,
  updateModifiers,
  updatePriceForAllItem,
  updatePriceForItem,
  updateSpecificItemSelectedModifiers,
} from 'src/redux/slices/itemCustomizationSlice';
import { useAppDispatch, useAppSelector } from 'src/redux/store/store';

import styles from '../ItemDetail/itemDetails.module.scss';
import Customization from '../Modifiers/Customization';
import PackegeCustomization from '../Modifiers/PackegeCustomization';
import { finalOrder } from 'src/helper/boxLunch';

const OtherManuItems = () => {
  const { search, state } = useLocation();
  const isPremiumPackage =
    (state as { isPremiumPackage?: boolean })?.isPremiumPackage ?? false;

  const [itemInfo, setItemInfo] = useState<boolean>(false);
  const [infoItem, setInfoItem] = useState<ItemDetails>();
  const [manualSelectedItem, setManualSelectedItem] = useState<ItemDetails>();
  const manualSelectedItemId = isPremiumPackage
    ? CONSTANT.PREMIUM_PACKAGE_SELECTED_SECTION
    : CONSTANT.PACKAGE_SELECTED_SECTION;
  const [customizedItem, setCustomizedItem] = useState<cartItem>(null);
  const [showAddtoOrderModal, setshowAddtoOrderModal] =
    useState<boolean>(false);
  const [addToOrderStickyButton, setAddToOrderStickyButton] = useState(false);

  const [itemsAsModifier, setItemsAsModifier] = useState<IItemAsModifiers[]>(
    [],
  );
  const copyOfItemAsModifiers: MutableRefObject<IItemAsModifiers[] | null> =
    useRef(null);

  const history = useHistory();
  const locationInfo = useAppSelector((state) => state.location);

  const recentlyDeletedId = useSelector(
    (state: any) => state.cart.recentlyDeletedId,
  );

  const query = new URLSearchParams(search);
  const category_id = query.get('category_id');
  const { id: item_id } = useParams<any>();
  const { user: authInfo } = useAppSelector((state) => state.user);

  const location_id = locationInfo.selectedStore?.id;

  const isBoxLunch = query.get('is_box_lunch');
  const isPackage = Boolean(query.get('is_package'));
  const {
    data: item,
    isFetching,
    refetch: refetchItem,
    isError: isUseItemError,
    error: useItemError,
  } = useItem({
    item_id: item_id,
    location_id: location_id,
    category_id: category_id,
    user: authInfo,
  });

  const dispatch = useAppDispatch();
  const order = useSelector((state: any) => state.itemCustomization);

  const dbId = query.get('id');

  // custom Hooks Callings Start
  const payload: IGetItemFromCartPayload = {
    id: dbId,
    location_id: location_id,
  };
  if (authInfo.id) payload.customer_id = authInfo.id;
  else payload.visitor_id = getVisitorId();

  const {
    data: editCartItem,
    refetch,
    isFetching: editItemFetching,
    isError,
  } = useGetCartItem(payload);

  const {
    cleanItemAsModifiersPrice,
    addItemAsModifiersPrice,
    IsAllItemInModifierSelected,
  } = useItemAsModifiers(
    item,
    _clonedeep(itemsAsModifier),
    order,
    {
      itemsAsModifier,
      setItemsAsModifier,
    },
    dbId,
    editCartItem,
    isPackage,
    isPremiumPackage,
  );

  const queryClient = useQueryClient();

  const openItemInfoModal = (item) => {
    setInfoItem(item);
    setItemInfo(true);
  };
  useEffect(() => {
    if (item && (item.is_box_lunch || isBoxLunch === 'true')) {
      history.push(CONSTANT.ROUTE_MENU);
    }
  }, [item]);

  const isMobile = useCheckMobileScreen();

  const { addToOrder, loading } = useAddToOrder();

  const {
    refToScrollToMissingRequiredArea,
    isCYOIRequiredModifierSelected,
    setisCYOIRequiredModifierSelected,
    itemsAsModifiersToHandleScroll,
    requiredModifiersAtItemDetailSectionToHandleScroll,
    subItemRequiredModifiersAtItemDetailSectionToHandleScroll,
    requiredModifiersForDynamicSelectedItemAtItemDetailSectionToHandleScroll,
    requiredItemForDynamicSelectedItemAtItemDetailSectionToHandleScroll,
  } = useScrollToMissingRequiredArea(item, manualSelectedItem);

  // custom Hooks Callings End

  const [showItemCustomisation, setShowItemCustomisation] =
    useState<boolean>(false);
  const [showItemCustomisationModal, setShowItemCustomisationModal] =
    useState<boolean>(false);
  const scrollRef = useRef<HTMLDivElement>(null);
  const modifiersRef = useRef([]);
  const autoScrollDivRef = useRef<HTMLDivElement>(null);
  const [startScrollingForMobile, setStartScrollingForMobile] = useState<{
    status: boolean;
    orderButtonStatus: any;
    updateStateFor:
      | typeof CONSTANT.CREATE_YOUR_OWN_ITEM
      | typeof CONSTANT.ITEM_AS_MODIFIERS
      | null;
  }>({
    status: false,
    orderButtonStatus: null,
    updateStateFor: null,
  });

  const handleShowItemCustomisation = (): void => {
    setShowItemCustomisation(true);
    setShowItemCustomisationModal(true);
  };

  const handleScrollToCustomisation = () => {
    setTimeout(() => {
      const yOffset = -350;
      if (scrollRef.current) {
        const ScrollYCordinates =
          scrollRef?.current?.getBoundingClientRect().top +
          window.pageYOffset +
          yOffset;
        window.scrollTo({ top: ScrollYCordinates, behavior: 'smooth' });
      }
    }, 500);
  };
  const [showCustomizedOptions, setShowCustomizedOptions] =
    useState<boolean>(false);
  const [personalize, setPersonalize] = useState<boolean>(false);
  const [forceRender, setForceRender] = useState<boolean>(false);

  const [firstTimeModal, setFirstTimeModal] = useState<boolean>(false);
  const orderType = useSelector((state: any) => state.cart.orderType);

  const showCustomization =
    (state as { showCustomization?: boolean })?.showCustomization ?? false;

  const [formState, setFormState] = useState<ItemDetailForm>({
    quantity: 1,
    name: [],
    instructions: '',
    reset: false,
  });

  const isCreateYourOwnItem = isItCreateYourOwnItem(item?.is_create_your_own);

  useEffect(() => {
    if (!location_id) history.push('/menu');
  }, [location_id]);

  useEffect(() => {
    if (!showCustomization || !dbId) return;
    if (recentlyDeletedId === Number(dbId) || isError) {
      Toast_Func({ message: 'This Item does not exist!', status: false });
      history.push('/menu');
    }
  }, [recentlyDeletedId, isError]);

  useEffect(() => {
    if (showItemCustomisationModal) {
      modifiersRef.current = [...order.modifiers];
    }
  }, [showItemCustomisationModal]);

  useEffect(() => {
    if (showCustomization && dbId) {
      dispatch(resetContext());
      setManualSelectedItem(null);
      itemBuckets.resetBuckets();
      itemBuckets.createComboBucket({
        keys: [
          CONSTANT.REQUIRED_MODIFIERS,
          CONSTANT.CORE_MODIFIERS,
          CONSTANT.ADD_ONS,
          CONSTANT.COMPLIMENTARY_MODIFIER,
          CONSTANT.ITEM_AS_MODIFIERS,
        ],
        items: isPackage
          ? isPremiumPackage
            ? CONSTANT.PREMIUM_PACKAGE_SELECTED_SECTION
            : CONSTANT.PACKAGE_SELECTED_SECTION
          : CONSTANT.SINGLE_ITEM_SELECTED_SECTION,
        handleItemOwnModifiers: isPackage ? true : false,
      });
      refetchItem();
      refetch();
    }
  }, [showCustomization, dbId]);

  useEffect(() => {
    if (item && order.modifiers.length === 0) {
      isPackage
        ? dispatch(addPackagesItem({ item, isPremium: isPremiumPackage }))
        : dispatch(addItem(item));
    }
  }, [item, isFetching]);

  useEffect(() => {
    if (item && editCartItem && showCustomization && !editItemFetching) {
      !isPackage && dispatch(editItem(editCartItem));
      setFormState({
        ...formState,
        quantity: editCartItem?.quantity,
        name:
          editCartItem?.recipient_name !== null
            ? editCartItem?.recipient_name.split(',')
            : [],
        instructions:
          editCartItem?.special_instructions !== null
            ? editCartItem?.special_instructions
            : '',
      });
    }
  }, [editCartItem, editItemFetching, item]);

  useEffect(() => {
    itemBuckets.createComboBucket({
      keys: [
        CONSTANT.REQUIRED_MODIFIERS,
        CONSTANT.CORE_MODIFIERS,
        CONSTANT.ADD_ONS,
        CONSTANT.COMPLIMENTARY_MODIFIER,
        CONSTANT.ITEM_AS_MODIFIERS,
      ],
      items: isPackage
        ? isPremiumPackage
          ? CONSTANT.PREMIUM_PACKAGE_SELECTED_SECTION
          : CONSTANT.PACKAGE_SELECTED_SECTION
        : CONSTANT.SINGLE_ITEM_SELECTED_SECTION,
      handleItemOwnModifiers: isPackage ? true : false,
    });
    refetchItem();
    return () => {
      itemBuckets.resetBuckets();
      dispatch(resetContext());
      setManualSelectedItem(null);
    };
  }, []);

  useEffect(() => {
    const currentState: any = history.location.state;
    if (item_id && location_id && currentState?.is_recommended) {
      itemBuckets.resetBuckets();
      dispatch(resetContext());
      setManualSelectedItem(null);
      itemBuckets.createComboBucket({
        keys: [
          CONSTANT.REQUIRED_MODIFIERS,
          CONSTANT.CORE_MODIFIERS,
          CONSTANT.ADD_ONS,
          CONSTANT.COMPLIMENTARY_MODIFIER,
          CONSTANT.ITEM_AS_MODIFIERS,
        ],
        items: isPackage
          ? isPremiumPackage
            ? CONSTANT.PREMIUM_PACKAGE_SELECTED_SECTION
            : CONSTANT.PACKAGE_SELECTED_SECTION
          : CONSTANT.SINGLE_ITEM_SELECTED_SECTION,
        handleItemOwnModifiers: isPackage ? true : false,
      });
      refetchItem();
    }
  }, [item_id]);

  /**
   * We'll use this hook after
   * @method {componentDidMount} and {componentWillUnMount}
   * @requires [This order must be followed]
   */
  const isCoreForRequiredTraysAdded = useRef(false);

  const {
    prepareBucketAndStartSyncForSingleItem,
    prepareBucketAndStartSyncForPackages,
  } = useHandleByDefaultModifierSelections(
    item,
    showCustomization,
    editCartItem,
    isPackage,
    isPremiumPackage,
    manualSelectedItem,
    setManualSelectedItem,
    dbId,
    isCoreForRequiredTraysAdded,
  );
  /**
   * End
   */

  const togglePersonalize = () => {
    setPersonalize(!personalize);
  };

  const unrepeatedIngredients = item
    ? [
        ...item.core_modifiers,
        ...item.items_required_modifier_groups
          .map((group) => group.required_modifiers_groups_modifiers)
          .flat(),
      ]
    : [];

  const handleIngredientSelection: (data: any, id?: 1 | 2 | 3 | 4) => void = (
    data,
    id = CONSTANT.SINGLE_ITEM_SELECTED_SECTION,
    priorIdForCustomizationSlice = false,
  ) => {
    const { itemId, activeBucket, itemModifiers } = calculatePriceForItem({
      currentModifier: data,
      item_id: id,
    });
    dispatch(
      updateSpecificItemSelectedModifiers({
        itemModifiers,
        itemId: priorIdForCustomizationSlice
          ? priorIdForCustomizationSlice
          : itemId,
      }),
    );
    if (activeBucket !== undefined) {
      dispatch(updatePriceForItem({ itemId, activeBucket }));
    }
    setForceRender(!forceRender);
  };

  const handleComplementaryIngredientSelection: (
    data: any,
    id?: 1 | 2 | 3 | 4,
  ) => void = (
    data,
    id = CONSTANT.COMPLEMENTARY_SELECTED_SECTION_FOR_SINGLE_ITEM,
  ) => {
    const { itemId, activeBucket, itemModifiers } = calculatePriceForItem({
      currentModifier: data,
      item_id: id,
    });
    dispatch(updateSpecificItemSelectedModifiers({ itemModifiers, itemId }));
    if (activeBucket !== undefined) {
      dispatch(updatePriceForItem({ itemId, activeBucket }));
    }
    setForceRender(!forceRender);
  };

  const setSize = (activeItem) => {
    let currentItem = _clonedeep(item);
    if (isPackage && !dbId) {
      currentItem = activeItem;
    }
    if (isPackage && dbId) {
      currentItem = item.package_categories.find(
        (tray) => tray.id === activeItem.category_id,
      ).items[0];
    }
    if (currentItem.is_soup_tray) {
      return CONSTANT.HALF_SIZE.id;
    }
    if (currentItem.half_brink_item_id) {
      return CONSTANT.HALF_SIZE.id;
    }
    return CONSTANT.FULL_SIZE.id;
  };

  const addItemsAsModifierInPayload = (structuredOrder) => {
    const newItemsAsModifiers = _clonedeep(
      itemsAsModifier.filter((currentItemAsModifier: any) => {
        if (currentItemAsModifier.parentItem?.cart_id && isPackage) {
          return (
            currentItemAsModifier.parentItem.modifier_id ===
            structuredOrder.item_id
          );
        }
        if (currentItemAsModifier.parentItem?.cart_id) {
          return (
            currentItemAsModifier.parentItem.item_id === structuredOrder.item_id
          );
        }
        return currentItemAsModifier.parentItem.id === structuredOrder.item_id;
      }),
    );
    const resultedArray = [];
    const selectedItemsRecord = new Set();
    // Itrate Over All Items Category
    for (let index = 0; index < newItemsAsModifiers.length; index++) {
      // Count quantity of same item objects
      const val = newItemsAsModifiers[index].selectedItems.reduce(function (
        arr,
        item,
      ) {
        let found = false;
        item.parentItem = newItemsAsModifiers[index]?.parentItem;
        for (let i = 0; i < arr.length; i++) {
          if (arr[i].id === item.id) {
            found = true;
            arr[i].quantity += item.quantity;
            arr[i].additionalPrice += item.additionalPrice;
          }
        }

        if (!found) {
          arr.push(item);
          selectedItemsRecord.add(item.id);
        }

        return arr;
      },
      []);
      resultedArray.push(val);
      // Add by default removed items to resulted array
      newItemsAsModifiers[index].byDefaultSelectedItems.map(
        (item: any, currentDefaultSelectedIntdex: number): void => {
          if (!selectedItemsRecord.has(item.id)) {
            const payload = {
              modifier_id: item.id,
              modifier_name: item.name,
              modifier_group_id: item.modifier_group_id,
              display_price: item.additionalPrice,
              isCore: item.isByDefault ?? false,
              quantity: 0,
              code: CONSTANT.NO,
              modifier_type: CONSTANT.ITEM_AS_MODIFIERS,
              size: setSize(newItemsAsModifiers[index]?.parentItem),
              is_item: true,
            };
            resultedArray.push(payload);
            selectedItemsRecord.add(item.id);
          }
        },
      );
    }
    // Add codes [Add, No] in items
    resultedArray.flat().map((item: any, index: number): void => {
      if (item.quantity === 0) {
        structuredOrder.modifiers.push(item);
      } else {
        const payload = {
          modifier_id: item.id,
          modifier_name: item.name,
          modifier_group_id: item.modifier_group_id,
          display_price: item.additionalPrice,
          isCore: item.isByDefault ?? false,
          quantity: item.quantity,
          code: modifierCode(item),
          modifier_type: CONSTANT.ITEM_AS_MODIFIERS,
          size: setSize(item.parentItem),
          is_item: true,
        };
        selectedItemsRecord.add(item.id);
        structuredOrder.modifiers.push(payload);
      }
    });
    return structuredOrder;
  };

  const resetItems = () => {
    queryClient.resetQueries('get-cart-item');
    setFormState({
      quantity: 1,
      name: [],
      instructions: '',
      reset: true,
    });
    dispatch(resetContext());
    setManualSelectedItem(null);
    isPackage
      ? dispatch(addPackagesItem({ item, isPremium: isPremiumPackage }))
      : dispatch(addItem(item));
    itemBuckets.createComboBucket({
      keys: [
        CONSTANT.REQUIRED_MODIFIERS,
        CONSTANT.CORE_MODIFIERS,
        CONSTANT.ADD_ONS,
        CONSTANT.COMPLIMENTARY_MODIFIER,
        CONSTANT.ITEM_AS_MODIFIERS,
      ],
      items: isPackage
        ? isPremiumPackage
          ? CONSTANT.PREMIUM_PACKAGE_SELECTED_SECTION
          : CONSTANT.PACKAGE_SELECTED_SECTION
        : CONSTANT.SINGLE_ITEM_SELECTED_SECTION,
      handleItemOwnModifiers: isPackage ? true : false,
    });
    if (item) {
      isPackage
        ? prepareBucketAndStartSyncForPackages()
        : prepareBucketAndStartSyncForSingleItem();
    }
  };

  const addToOrderHandler = async (structuredOrder) => {
    // let finalOrderObject: any = null;
    if (item.is_package) {
      const category = Number(category_id);
      // eslint-disable-next-line prefer-const
      structuredOrder = finalOrder({
        order,
        category_id: category,
        noOfItems: isPremiumPackage
          ? CONSTANT.PREMIUM_PACKAGE_SELECTED_SECTION
          : CONSTANT.PACKAGE_SELECTED_SECTION,
        comboType: CONSTANT.COMBO_TYPE_PACKAGES,
        items: item,
        totalSelectedSection: isPremiumPackage
          ? CONSTANT.PREMIUM_PACKAGE_SELECTED_SECTION
          : CONSTANT.PACKAGE_SELECTED_SECTION,
      });
      structuredOrder.recipient_name = formState.name.toString();
      structuredOrder.is_package = true;
      if (formState.quantity) structuredOrder.quantity = formState.quantity;
      structuredOrder['special_instructions'] = formState.instructions;

      //  ;
      if (item.is_full_size) {
        // structuredOrder.item_size = 'FULL';
        structuredOrder.modifiers.forEach((data, index) => {
          if (data.size) {
            structuredOrder.modifiers[index].size = 'FULL';
          }
        });
      }
      // if (item.is_single_item) {
      //   if (structuredOrder.modifiers[1].modifiers.length < 1) {
      //     structuredOrder.modifiers.pop();
      //   }
      // }
      const reqMod = structuredOrder.modifiers.findIndex(
        (item) => item.item === 'REQUIRED_ITEM',
      );
      if (reqMod !== undefined && reqMod >= 0) {
        const modifiers = _clonedeep(
          structuredOrder.modifiers[reqMod].modifiers,
        );
        structuredOrder.modifiers.splice(reqMod, 1);
        for (const modifier of modifiers) {
          structuredOrder.modifiers.push(modifier);
        }
      }
      const itemAsModifierTraysList = item.package_categories
        .filter((tray) => tray.items.length === 1)
        .filter(
          (filteredTray) =>
            filteredTray.items[0].sub_items_required_modifier_groups.length > 0,
        );
      for (let index = 0; index < itemAsModifierTraysList.length; index++) {
        structuredOrder.modifiers[index] = addItemsAsModifierInPayload(
          _clonedeep(structuredOrder.modifiers[index]),
        );
      }
    } else {
      structuredOrder = addItemsAsModifierInPayload(
        _clonedeep(structuredOrder),
      );
    }

    const response = await addToOrder(
      structuredOrder,
      isPackage ? true : false,
      structuredOrder?.quantity,
    );
    if (response) {
      setCustomizedItem(structuredOrder);
      setshowAddtoOrderModal(true);
      resetItems();
      setItemsAsModifier([]);
    }
    return response;
  };

  const hideModifierModal = () => {
    setShowItemCustomisationModal(false);
  };

  const hideFirstTimeModal = () => {
    setFirstTimeModal(false);
    setShowCustomizedOptions(true);
  };

  const showFirstTimeModal = () => {
    itemBuckets?.createBucketCopy();
    setFirstTimeModal(true);
  };

  const showModifierModal = () => {
    if (isMobile) {
      itemBuckets.createBucketCopy();
      copyOfItemAsModifiers.current = _clonedeep(itemsAsModifier);
      setShowItemCustomisationModal(true);
    } else {
      setShowCustomizedOptions(true);
    }
  };

  const applyClickHandler = () => {
    let currentItems: any = [item];
    if (item.is_package) {
      currentItems = item.package_categories
        .filter((tray: ISubCategories) => tray.items.length === 1)
        .map((activeItem) => activeItem.items[0]);
    }
    for (let index = 0; index < currentItems.length; index++) {
      if (currentItems[index]?.sub_items_required_modifier_groups?.length > 0) {
        const isCustomizeSectionExposed =
          showItemCustomisationModal ||
          showCustomizedOptions ||
          itemsAsModifier.length > 0;
        const { status: isAllItemAsModifierSelected, highLightSection } =
          IsAllItemInModifierSelected({
            item: currentItems[index],
            isCustomizeSectionExposed,
          });
        if (isMobile && !isAllItemAsModifierSelected) {
          setStartScrollingForMobile({
            status: true,
            orderButtonStatus: {
              status: !isAllItemAsModifierSelected,
              missingGroupIndex: highLightSection,
            },
            updateStateFor: CONSTANT.ITEM_AS_MODIFIERS,
          });
          return;
        }
      }
    }
    itemBuckets.resetCopyComboBucket();
    hideModifierModal();
  };

  useEffect(() => {
    if (item && isMobile && isCreateYourOwnItem && !dbId) {
      showModifierModal();
    }
  }, [item]);

  const onModalCancel = () => {
    if (item.is_package) {
      const itemLength = isPremiumPackage
        ? CONSTANT.PREMIUM_PACKAGE_SELECTED_SECTION
        : CONSTANT.PACKAGE_SELECTED_SECTION;
      dispatch(
        removeTemporarilyAddedPriceForItem({
          price: itemBuckets.discardBucketChangesForPackages(itemLength),
          item: itemLength,
        }),
      );
    } else {
      dispatch(
        removeTemporarilyAddedPriceForItem({
          price: itemBuckets.discardBucketChanges('1'),
          item: '1',
        }),
      );
    }
    dispatch(resetModifiers({ modifiers: modifiersRef.current }));
    cleanItemAsModifiersPrice();
    setItemsAsModifier((prevState) => {
      const newState = _clonedeep(copyOfItemAsModifiers.current);
      addItemAsModifiersPrice(newState);
      copyOfItemAsModifiers.current = null;
      return newState;
    });
    hideModifierModal();
  };

  const isBucketCreated = () => {
    if (item?.is_package && item?.is_premium) {
      return itemBuckets.getBuckets()?.length < 4;
    }
    if (item?.is_package) {
      return itemBuckets.getBuckets()?.length < 3;
    }
    return itemBuckets.getBuckets()?.length < 2;
  };

  return isFetching || isBucketCreated() ? (
    <Loader />
  ) : (
    <>
      <div className={`${styles.item_detail_wraper}`}>
        <Container fluid>
          {item && order.modifiers.length > 0 && (
            <ItemDetail
              item={item}
              state={state}
              togglePersonalize={togglePersonalize}
              showItemCustomiseModal={() => handleShowItemCustomisation()}
              showModifierModal={showModifierModal}
              handleScrollToCustomisation={handleScrollToCustomisation}
              handleIngredientSelection={handleIngredientSelection}
              handleComplementaryIngredientSelection={
                handleComplementaryIngredientSelection
              }
              openItemInfoModal={openItemInfoModal}
              addToOrder={(structuredOrder) =>
                addToOrderHandler(structuredOrder)
              }
              setManualSelectedItem={setManualSelectedItem}
              isCoreForRequiredTraysAdded={isCoreForRequiredTraysAdded}
              manualSelectedItem={manualSelectedItem}
              manualSelectedItemId={manualSelectedItemId}
              formState={formState}
              setFormState={setFormState}
              activeKey={item?.category_name}
              itemsAsModifier={itemsAsModifier}
              isCustomizeSectionExposed={
                showItemCustomisationModal ||
                showCustomizedOptions ||
                itemsAsModifier.length > 0
              }
              customizedItem={customizedItem}
              showAddtoOrderModal={showAddtoOrderModal}
              setshowAddtoOrderModal={setshowAddtoOrderModal}
              loading={loading}
              addToOrderButtonShown={(state: boolean) =>
                setAddToOrderStickyButton(state)
              }
              showCustomization={showCustomization}
              showItemCustomisationModal={showItemCustomisationModal}
              CYOIScrollToMissingRequiredArea={{
                refToScrollToMissingRequiredArea,
                isCYOIRequiredModifierSelected,
                setisCYOIRequiredModifierSelected,
              }}
              itemsAsModifiersToHandleScroll={itemsAsModifiersToHandleScroll}
              requiredModifiersAtItemDetailSectionToHandleScroll={
                requiredModifiersAtItemDetailSectionToHandleScroll
              }
              subItemRequiredModifiersAtItemDetailSectionToHandleScroll={
                subItemRequiredModifiersAtItemDetailSectionToHandleScroll
              }
              requiredModifiersForDynamicSelectedItemAtItemDetailSectionToHandleScroll={
                requiredModifiersForDynamicSelectedItemAtItemDetailSectionToHandleScroll
              }
              requiredItemForDynamicSelectedItemAtItemDetailSectionToHandleScroll={
                requiredItemForDynamicSelectedItemAtItemDetailSectionToHandleScroll
              }
              stateJustForOtherMenuItems={{
                startScrollingForMobile,
                setStartScrollingForMobile,
                IsAllItemInModifierSelected,
              }}
              isPremiumPackage={isPremiumPackage}
              editCartItem={editCartItem}
            />
          )}

          <div ref={scrollRef}>
            <>
              {!isMobile ? (
                <div>
                  {item?.is_package ? (
                    <PackegeCustomization
                      item={item}
                      showCustomizedOptions={showCustomizedOptions}
                      showCustomization={false}
                      handleIngredientSelection={handleIngredientSelection}
                      autoScrollDivRef={autoScrollDivRef}
                      firstTimeModal={firstTimeModal}
                      itemAsModifierState={{
                        itemsAsModifier,
                        setItemsAsModifier,
                      }}
                      manualSelectedItem={manualSelectedItem}
                      manualSelectedItemId={manualSelectedItemId}
                      itemsAsModifiersToHandleScroll={
                        itemsAsModifiersToHandleScroll
                      }
                      openItemInfoModal={openItemInfoModal}
                    />
                  ) : (
                    <Customization
                      item={item}
                      showCustomizedOptions={showCustomizedOptions}
                      hideModifierModal={hideModifierModal}
                      showCustomization={dbId ? true : false}
                      handleIngredientSelection={handleIngredientSelection}
                      autoScrollDivRef={autoScrollDivRef}
                      firstTimeModal={firstTimeModal}
                      itemAsModifierState={{
                        itemsAsModifier,
                        setItemsAsModifier,
                      }}
                      isCreateYourOwnItem={isCreateYourOwnItem}
                      CYOIScrollToMissingRequiredArea={{
                        refToScrollToMissingRequiredArea,
                        isCYOIRequiredModifierSelected,
                        setisCYOIRequiredModifierSelected,
                      }}
                      itemsAsModifiersToHandleScroll={
                        itemsAsModifiersToHandleScroll
                      }
                      hideFirstTimeModal={hideFirstTimeModal}
                      showFirstTimeModal={showFirstTimeModal}
                      modifiersRef={modifiersRef}
                      dbId={dbId}
                      openItemInfoModal={openItemInfoModal}
                    />
                  )}
                </div>
              ) : (
                <ItemCustomizationModal
                  showModal={showItemCustomisationModal}
                  closeModal={onModalCancel}
                  title={'Customize This Item'}
                  applyClickHandler={applyClickHandler}
                >
                  {item?.is_package ? (
                    <PackegeCustomization
                      item={item}
                      showCustomizedOptions={showItemCustomisationModal}
                      showCustomization={false}
                      handleIngredientSelection={handleIngredientSelection}
                      autoScrollDivRef={autoScrollDivRef}
                      firstTimeModal={firstTimeModal}
                      itemAsModifierState={{
                        itemsAsModifier,
                        setItemsAsModifier,
                      }}
                      manualSelectedItem={manualSelectedItem}
                      manualSelectedItemId={manualSelectedItemId}
                      itemsAsModifiersToHandleScroll={
                        itemsAsModifiersToHandleScroll
                      }
                      openItemInfoModal={openItemInfoModal}
                    />
                  ) : (
                    <Customization
                      item={item}
                      showCustomizedOptions={showItemCustomisationModal}
                      hideModifierModal={hideModifierModal}
                      showCustomization={dbId ? true : false}
                      handleIngredientSelection={handleIngredientSelection}
                      autoScrollDivRef={autoScrollDivRef}
                      firstTimeModal={firstTimeModal}
                      itemAsModifierState={{
                        itemsAsModifier,
                        setItemsAsModifier,
                      }}
                      isCreateYourOwnItem={isCreateYourOwnItem}
                      CYOIScrollToMissingRequiredArea={{
                        refToScrollToMissingRequiredArea,
                        isCYOIRequiredModifierSelected,
                        setisCYOIRequiredModifierSelected,
                      }}
                      itemsAsModifiersToHandleScroll={
                        itemsAsModifiersToHandleScroll
                      }
                      hideFirstTimeModal={hideFirstTimeModal}
                      showFirstTimeModal={showFirstTimeModal}
                      modifiersRef={modifiersRef}
                      dbId={dbId}
                      // cardColSize={'col-lg-2 col-md-2 col-sm-4 col-4'}
                      // firstRef={firstRef}
                    />
                  )}
                </ItemCustomizationModal>
              )}
            </>
          </div>
        </Container>
        <CustomModal
          showModal={itemInfo}
          closeModal={() => setItemInfo(false)}
          title={''}
          modalDialogClasses={styles.item_info_modal}
        >
          <ItemInfoModal item={infoItem} />
        </CustomModal>
      </div>
    </>
  );
};

const useHandleByDefaultModifierSelections = (
  item,
  showCustomization,
  editCartItem,
  isPackage,
  isPremiumPackage,
  manualSelectedItem,
  setManualSelectedItem,
  dbId,
  isCoreForRequiredTraysAdded,
) => {
  const dispatch = useAppDispatch();
  useEffect(() => {
    let requiredTrayItems = null;
    if (item && item?.package_categories) {
      requiredTrayItems = item.package_categories.filter(
        (tray: ISubCategories) => tray.items.length > 1,
      );
    }
    if (item && !requiredTrayItems?.length && item?.package_categories) {
      requiredTrayItems = item.package_categories
        .filter((tray: ISubCategories) => tray.items.length === 1)
        .filter(
          (filteredTray) =>
            filteredTray.items[0].sub_items_required_modifier_groups.length ===
            0,
        );
    }
    if (
      item &&
      editCartItem &&
      editCartItem?.id == dbId &&
      item.is_package &&
      requiredTrayItems?.length > 0
    ) {
      isCoreForRequiredTraysAdded.current = true;
      const id = isPremiumPackage
        ? CONSTANT.PREMIUM_PACKAGE_SELECTED_SECTION
        : CONSTANT.PACKAGE_SELECTED_SECTION;
      const currentSelectedRequiredItem = requiredTrayItems[0].items.find(
        (item) => item.id === editCartItem.modifiers[id - 1].modifier_id,
      );

      setManualSelectedItem(currentSelectedRequiredItem);
      dispatch(
        try2ComboItemSelection({
          sectionSelected: item.is_premium
            ? CONSTANT.PREMIUM_PACKAGE_SELECTED_SECTION.toString()
            : CONSTANT.PACKAGE_SELECTED_SECTION.toString(),
          item: currentSelectedRequiredItem,
        }),
      );
    }
  }, [editCartItem, item]);

  useEffect(() => {
    if (
      item?.is_package &&
      manualSelectedItem &&
      !isCoreForRequiredTraysAdded.current
    ) {
      const id = isPremiumPackage
        ? CONSTANT.PREMIUM_PACKAGE_SELECTED_SECTION
        : CONSTANT.PACKAGE_SELECTED_SECTION;
      itemBuckets.addCoreModifierMemoryChip(
        id,
        manualSelectedItem?.bucket,
        manualSelectedItem?.items_modifier_groups,
      );
      handleSyncIngredients(manualSelectedItem, id);
    }
  }, [manualSelectedItem, dbId]);

  const noDressingHandler = (modifiers: any, itemNo: number) => {
    for (let i = 0; i < modifiers.length; i++) {
      itemBuckets.resetChangesOnNoDreesing(itemNo, {
        ...modifiers[i],
        quantity: 1,
      });
    }
  };

  useEffect(() => {
    isPackage
      ? prepareBucketAndStartSyncForPackages()
      : prepareBucketAndStartSyncForSingleItem();
  }, [item, showCustomization, editCartItem]);

  const prepareBucketAndStartSyncForSingleItem = () => {
    item &&
      itemBuckets.addCoreModifierMemoryChip(
        CONSTANT.SINGLE_ITEM_SELECTED_SECTION,
        item?.bucket,
        item?.items_modifier_groups,
      );
    item && handleSyncIngredients(item, CONSTANT.SINGLE_ITEM_SELECTED_SECTION);
  };

  const prepareBucketAndStartSyncForPackages = () => {
    if (item && !dbId) {
      const requriedTraysItems = item.package_categories
        .filter((tray: ISubCategories) => tray.items.length === 1)
        .map((tray) => tray.items[0]);
      for (let index: any = 1; index <= requriedTraysItems.length; index++) {
        itemBuckets.addCoreModifierMemoryChip(
          index,
          requriedTraysItems[index - 1].bucket,
          requriedTraysItems[index - 1].items_modifier_groups,
        );
        handleSyncIngredients(requriedTraysItems[index - 1], index);
      }
    }
    if (item && editCartItem?.id == dbId) {
      handleInitialSyncForPackages();
    }
  };

  const handleSyncIngredientsForPackages = async (
    item,
    id = null,
    cartItem = null,
    zeroPriceCore = [],
  ) => {
    const itemID = id;
    let ingredients = _clonedeep(availableModifiers(item));
    if (ingredients.length) {
      ingredients = sortBasedOnPriceByModifierGroups(ingredients);
    }
    for (let i = 0; i < ingredients.length; i++) {
      let customizedIngredient = null;
      let iterations = 1;
      const quantity = parseInt(ingredients[i].quantity) || 1;
      const data: any = {
        modifier_id: ingredients[i].id,
        modifier_name: ingredients[i].name,
        modifier_group_id: ingredients[i].modifier_group_id,
        modifier_calories: ingredients[i].calories,
        brink_id: ingredients[i].brink_modifier_id,
        quantity: quantity,
        brink_modifier_group_id: ingredients[i].brink_modifier_group_id,
        display_price: ingredients[i].price,
        core: true,
        type: CONSTANT.BY_DEFAULT,
        modifier_type: CONSTANT.CORE_MODIFIERS,
      };
      //In case of Edit update core modifier selection
      if (showCustomization && cartItem?.core_modifiers) {
        let customizedIngredients = _clonedeep(cartItem.core_modifiers ?? []);
        if (customizedIngredients.length) {
          customizedIngredients = sortBasedOnPriceByModifierGroups(
            customizedIngredients,
          );
        }
        customizedIngredient = customizedIngredients.find(
          (mod) => mod.modifier_id === ingredients[i].id,
        );
      }
      iterations =
        customizedIngredient?.quantity > 1 ? customizedIngredient?.quantity : 1;
      // save zero price core to add them at the end
      if (
        customizedIngredient?.price === 0 &&
        showCustomization &&
        cartItem?.core_modifiers
      ) {
        zeroPriceCore.push({
          customizedIngredient,
          data,
          iterations,
          quantity,
          itemID,
        });
        continue;
      }
      for (let j = 0; j < iterations; j++) {
        let tempQuantity = 0;
        if (customizedIngredient) data.code = customizedIngredient.code;
        if (data.code === CONSTANT.ADD) {
          tempQuantity = j + 1;
          data.type = j === 0 ? CONSTANT.BY_DEFAULT : CONSTANT.INCREASE;
          data.code = CONSTANT.ADD;
        } else if (data.code === CONSTANT.NO) {
          data.type = CONSTANT.DECREASE;
          tempQuantity = 0;
        } else {
          tempQuantity = quantity;
        }
        calculatePriceForItem({
          currentModifier: { ...data, quantity: tempQuantity },
          item_id: id,
        });
        if (data.code === CONSTANT.NO) break;
      }
    }
    await handleDefaultModifiers(item, itemID);
    // await handleSync86Ingredients(item, itemID);
    // if (!id) updateSingleBucket(itemID);
  };

  const handleInitialSyncForPackages = async (IsNotEdit = false) => {
    const zeroPriceCore = [];
    if (showCustomization && editCartItem && !IsNotEdit) {
      const sectionHandler = isPremiumPackage
        ? CONSTANT.PREMIUM_PACKAGE_SELECTED_SECTION
        : CONSTANT.PACKAGE_SELECTED_SECTION;
      const EditSingle = CONSTANT.PACKAGES;
      const itemId = isPremiumPackage
        ? CONSTANT.REQUIRED_AND_COMPLEMENTARY_SELECTED_SECTION_FOR_PREMIUM_PACKAGE
        : CONSTANT.REQUIRED_AND_COMPLEMENTARY_SELECTED_SECTION_FOR_PACKAGE;
      await processEditItemModifiers(editCartItem, item, EditSingle, itemId);
      await updateBucketAndReduxForPackages(itemId);

      // Start Syncing Items
      for (let i = 0; i < sectionHandler; i++) {
        const cartItem = editCartItem.modifiers[i];
        if (!cartItem?.is_item) {
          return;
        }
        const categoryId = cartItem.category_id;
        const section = (i + 1).toString();
        const category = item.package_categories.find(
          (category) => category.id === categoryId,
        );
        const INitem = category.items.find(
          (categoryItem) => categoryItem.id === cartItem.modifier_id,
        );

        itemBuckets?.addCoreModifierMemoryChip(
          i + 1,
          INitem?.bucket,
          INitem?.items_modifier_groups,
        );
        await handleSyncIngredientsForPackages(
          INitem,
          i + 1,
          cartItem,
          zeroPriceCore,
        );
        await processEditItemModifiers(
          cartItem,
          INitem,
          CONSTANT.TRY_TO_COMBO_ITEM,
          i + 1,
        );
        await handleZeroCode(zeroPriceCore, i + 1);
        if (cartItem.no_required_modifier) {
          await noDressingHandler(cartItem.no_required_modifier, i + 1);
        }
        await updateBucketAndReduxForPackages(i + 1);
      }
      // setItemDetails(true);
    }
    if (showCustomization && editCartItem && !IsNotEdit) {
      await editItemForPackages();
    }
  };

  const editItemForPackages = async () => {
    const itemCount = isPremiumPackage
      ? CONSTANT.PREMIUM_PACKAGE_SELECTED_SECTION
      : CONSTANT.PACKAGE_SELECTED_SECTION;
    for (let i = 0; i < itemCount; i++) {
      const cartItem = editCartItem.modifiers[i];
      const size = editCartItem.item_size;
      if (!cartItem.is_item) return;
      const categoryId = cartItem.category_id;
      const section = (i + 1).toString();
      const category = item?.package_categories.find(
        (category) => category.id === categoryId,
      );
      const INitem = category.items.find(
        (categoryItem) => categoryItem.id === cartItem.modifier_id,
      );
      dispatch(
        editComboItems({
          id: editCartItem.id,
          sectionSelected: section,
          item: INitem,
          category: category,
          itemName: CONSTANT.PACKAGES,
          size,
        }),
      );
    }
  };

  // Wrapper function to sync ingredients
  const handleSyncIngredients = async (
    currentItem: ItemDetails,
    currentItemId: 1 | 2 | 3 | 4,
  ) => {
    const { zeroPriceCore } = await handleCoreModifiers(
      currentItem,
      currentItemId,
    );
    await handleDefaultModifiers(currentItem, currentItemId);
    await handleSync86Ingredients(currentItem, zeroPriceCore, currentItemId);
    item.is_package
      ? updateBucketAndReduxForPackages(currentItemId)
      : updateRedux();
  };

  async function handleDefaultModifiers(
    currentItem: ItemDetails,
    currentItemId: 1 | 2 | 3 | 4,
  ) {
    let complementarySectionId: 2 | 3 | 4 =
      CONSTANT.COMPLEMENTARY_SELECTED_SECTION_FOR_SINGLE_ITEM;

    if (isPackage) {
      complementarySectionId =
        CONSTANT.REQUIRED_AND_COMPLEMENTARY_SELECTED_SECTION_FOR_PACKAGE;
    }

    if (isPackage && isPremiumPackage) {
      complementarySectionId =
        CONSTANT.REQUIRED_AND_COMPLEMENTARY_SELECTED_SECTION_FOR_PREMIUM_PACKAGE;
    }

    const selectedModifiers = currentItem.is_selected;
    const complimentaryModifiers = currentItem.complementary_items ?? [];
    complimentaryModifiers.forEach((modifier) => {
      modifier.modifiers_groups_modifiers?.forEach((selectedItem) => {
        if (!selectedItem.is_selected) return;
        const payload = createPayloadToSync(
          { ...selectedItem, modifier_type: CONSTANT.COMPLIMENTARY_MODIFIER },
          currentItem,
        );
        calculatePriceForItem({
          currentModifier: payload,
          item_id: complementarySectionId,
        });
      });
    });
    selectedModifiers.forEach((selectedItem) => {
      const payload = createPayloadToSync(
        { ...selectedItem, modifier_type: CONSTANT.CORE_MODIFIERS },
        currentItem,
      );
      calculatePriceForItem({
        currentModifier: payload,
        item_id: currentItemId,
      });
    });
    return true;
  }

  async function handleCoreModifiers(
    currentItem: ItemDetails,
    currentItemId: 1 | 2 | 3 | 4,
  ) {
    const zeroPriceCore = [];
    if (
      !isPackage &&
      showCustomization &&
      (!editCartItem ||
        (editCartItem && currentItem?.id !== editCartItem.item_id))
    )
      return { zeroPriceCore };
    const currentEditCartItemCoreModifiers =
      isPackage && isCoreForRequiredTraysAdded.current
        ? editCartItem?.modifiers.find(
            (currentModifierAsItem) =>
              currentModifierAsItem.modifier_id === currentItem.id,
          )?.core_modifiers
        : editCartItem?.core_modifiers;
    let ingredients: any = _clonedeep(availableModifiers(currentItem));
    if (ingredients?.length)
      ingredients = sortBasedOnPriceByModifierGroups(ingredients);
    for (let i = 0; i < ingredients?.length; i++) {
      let customizedIngredient = null;
      const quantity = parseInt(ingredients[i].quantity) || 1;
      let iterations = 1;
      const data: any = {
        modifier_id: ingredients[i].id,
        modifier_name: ingredients[i].name,
        modifier_group_id: ingredients[i].modifier_group_id,
        modifier_calories: ingredients[i].calories,
        brink_id: ingredients[i].brink_modifier_id,
        quantity: quantity,
        brink_modifier_group_id: ingredients[i].brink_modifier_group_id,
        display_price: ingredients[i].price,
        original_price: ingredients[i].original_price,
        core: true,
        type: CONSTANT.BY_DEFAULT,
        modifier_type: CONSTANT.CORE_MODIFIERS,
      };
      //In case of Edit update core modifier selection
      if (showCustomization && currentEditCartItemCoreModifiers) {
        let customizedIngredients = _clonedeep(
          currentEditCartItemCoreModifiers ?? [],
        );
        if (customizedIngredients.length) {
          customizedIngredients = sortBasedOnPriceByModifierGroups(
            customizedIngredients,
          );
        }
        customizedIngredient = customizedIngredients.find(
          (mod) => mod.modifier_id === ingredients[i].id,
        );
      }
      iterations =
        customizedIngredient?.quantity > 1 ? customizedIngredient?.quantity : 1;

      // save zero price core to add them at the end
      if (
        customizedIngredient?.price === 0 &&
        showCustomization &&
        currentEditCartItemCoreModifiers
      ) {
        zeroPriceCore.push({
          customizedIngredient,
          data,
          iterations,
          quantity,
        });
        continue;
      }
      for (let j = 0; j < iterations; j++) {
        let tempQuantity = 0;
        if (customizedIngredient) data.code = customizedIngredient.code;
        if (data.code === CONSTANT.ADD) {
          tempQuantity = j + 1;
          data.type = j === 0 ? CONSTANT.BY_DEFAULT : CONSTANT.INCREASE;
          data.code = CONSTANT.ADD;
        } else if (data.code === CONSTANT.NO) {
          data.type = CONSTANT.DECREASE;
          tempQuantity = 0;
        } else {
          tempQuantity = quantity;
        }
        const { itemId, activeBucket, itemModifiers } = calculatePriceForItem({
          currentModifier: { ...data, quantity: tempQuantity },
          item_id: currentItemId,
        });
        if (data.code === CONSTANT.NO) break;
      }
    }
    return { zeroPriceCore };
  }

  async function handleSync86Ingredients(
    currentItem: ItemDetails,
    zeroPriceCore,
    currentItemId,
  ) {
    //  ;  Start update order and verify paylaod, and verify if all added mofieir going correctly or not
    if (
      showCustomization &&
      editCartItem &&
      editCartItem?.id == dbId &&
      (isCoreForRequiredTraysAdded.current || !isPackage)
    ) {
      if (editCartItem.no_required_modifier.length > 0)
        await noDressingHandler(
          editCartItem.no_required_modifier,
          currentItemId,
        );
      await processEditItemModifiers(
        editCartItem,
        currentItem,
        CONSTANT.PACKAGES,
        currentItemId,
      );
      await handleZeroCode(zeroPriceCore, currentItemId);
    }
    const ingredients = unavailableModifiers(currentItem);
    for (let i = 0; i < ingredients.length; i++) {
      const data: any = {
        modifier_id: ingredients[i].id,
        modifier_name: ingredients[i].name,
        modifier_group_id: ingredients[i].modifier_group_id,
        modifier_group_min: ingredients[i].modifier_group_min,
        modifier_calories: ingredients[i].calories,
        brink_id: ingredients[i].brink_modifier_id,
        quantity: 0,
        brink_modifier_group_id: ingredients[i].brink_modifier_group_id,
        display_price: ingredients[i].price,
        type: CONSTANT.DECREASE,
        modifier_type: CONSTANT.CORE_MODIFIERS,
      };
      const { itemId, activeBucket, itemModifiers } = calculatePriceForItem({
        currentModifier: data,
        item_id: currentItemId,
      });
    }
    return true;
  }

  const updateRedux = () => {
    const priceArray = [];
    const itemBucket = itemBuckets.getBuckets();
    itemBucket?.forEach((currentItemBucket) => {
      const bucketTypes = Object.keys(currentItemBucket?.bucket);
      bucketTypes.forEach((type) => {
        const activeBucket = currentItemBucket?.bucket[type];
        const itemId =
          currentItemBucket?.item === 2 ? CONSTANT.COMPLEMENTARY_ITEM : 1;
        priceArray.push({ activeBucket, itemId });
      });
    });
    const otherModifiers = modifiersAgainstAnItem({
      itemId: CONSTANT.SINGLE_ITEM_SELECTED_SECTION,
    });
    const complimentaryModifiers = modifiersAgainstAnItem({
      itemId: CONSTANT.COMPLEMENTARY_SELECTED_SECTION_FOR_SINGLE_ITEM,
    });
    const updatedModifiers = [];

    updatedModifiers.push({ itemModifiers: otherModifiers, itemId: 1 });
    updatedModifiers.push({
      itemModifiers: complimentaryModifiers,
      itemId: CONSTANT.COMPLEMENTARY_ITEM,
    });

    dispatch(updateModifiers(updatedModifiers));
    dispatch(updatePriceForAllItem(priceArray));
  };

  const updateBucketAndReduxForPackages = (itemNo: any) => {
    const priceArray = [];
    let updatedBucketId: any = itemNo;
    const itemBucket = itemBuckets.getSingleItem({
      itemNo,
    });
    const bucketTypes = Object.keys(itemBucket?.bucket);
    bucketTypes.forEach((type) => {
      const activeBucket = itemBucket?.bucket[type];
      priceArray.push({ activeBucket, itemId: itemNo });
    });
    const itemModifiers = modifiersAgainstAnItem({
      itemId: itemNo,
    });
    const modifiers = [];
    if (!item.is_premium) {
      if (
        itemNo ===
        CONSTANT.REQUIRED_AND_COMPLEMENTARY_SELECTED_SECTION_FOR_PACKAGE
      ) {
        updatedBucketId = CONSTANT.REQUIRED_ITEM;
      }
    }
    if (
      item.is_premium &&
      itemNo ===
        CONSTANT.REQUIRED_AND_COMPLEMENTARY_SELECTED_SECTION_FOR_PREMIUM_PACKAGE
    ) {
      updatedBucketId = CONSTANT.REQUIRED_ITEM;
    }
    modifiers.push({ itemModifiers, itemId: updatedBucketId });

    dispatch(updateModifiers(modifiers));
    dispatch(updatePriceForAllItem(priceArray));
  };
  // Core Modifeir For Salad not includedin parice calculation, because pricecalcultion funciton (update modifier in update...for..pcakges) run first then bucket for salad get updated
  const handleZeroCode = async (
    zeroPriceCore: {
      customizedIngredient: any;
      data: any;
      iterations: number;
      quantity: number;
    }[],
    item_id: any,
  ) => {
    zeroPriceCore.forEach((element) => {
      const { customizedIngredient, data, iterations, quantity } = element;
      for (let j = 0; j < iterations; j++) {
        let tempQuantity = 0;
        if (customizedIngredient) data.code = customizedIngredient.code;
        if (data.code === CONSTANT.ADD) {
          tempQuantity = j + 1;
          data.type = j === 0 ? CONSTANT.BY_DEFAULT : CONSTANT.INCREASE;
          data.code = CONSTANT.ADD;
        } else if (data.code === CONSTANT.NO) {
          data.type = CONSTANT.DECREASE;
          tempQuantity = 0;
        } else {
          tempQuantity = quantity;
        }
        const { itemId, activeBucket, itemModifiers } = calculatePriceForItem({
          currentModifier: { ...data, quantity: tempQuantity },
          item_id,
        });
        if (data.code === CONSTANT.NO) break;
      }
    });
  };

  return {
    prepareBucketAndStartSyncForPackages,
    prepareBucketAndStartSyncForSingleItem,
  };
};

const useItemAsModifiers = (
  currentItem,
  items: any,
  order,
  itemAsModifierState,
  dbId: string,
  editCartItem,
  isPackage: boolean,
  isPremiumPackage,
) => {
  const { itemsAsModifier, setItemsAsModifier } = itemAsModifierState;
  let currentlySelectedItemsAsModifier: IItemAsModifiers[] = [];
  const shouldStartAdddingByDefaultOnSizeChange = useRef(false);
  let isItemAsModifierCleaningDoneOnMobileView = false;
  const dispatch = useAppDispatch();
  const isMobile = useCheckMobileScreen();

  useEffect(() => {
    // it must run on top for cleaning purpose
    if (editCartItem) {
      currentlySelectedItemsAsModifier = [];
      shouldStartAdddingByDefaultOnSizeChange.current = false;
      isItemAsModifierCleaningDoneOnMobileView = false;
    }
  }, [editCartItem]);

  useEffect(() => {
    if (order.size && isMobile) {
      if (items.length > 0) {
        if (shouldStartAdddingByDefaultOnSizeChange.current) {
          cleanItemAsModifiersPrice();
        }
        setItemsAsModifier([]);
        isItemAsModifierCleaningDoneOnMobileView = true;
      }
    }
  }, [order.size]);

  useEffect(() => {
    if (
      ((order.size && !dbId) ||
        shouldStartAdddingByDefaultOnSizeChange.current) &&
      currentItem
    ) {
      if (isPackage) {
        currentItem?.package_categories
          .filter((tray: ISubCategories) => tray.items.length === 1)
          .map((tray) => {
            addByDefaultItems(
              tray.items[0].sub_items_required_modifier_groups,
              tray.items[0],
            );
          });
      } else {
        addByDefaultItems(
          currentItem?.sub_items_required_modifier_groups,
          currentItem,
        );
      }
    }
  }, [order.size]);

  useEffect(() => {
    if (
      editCartItem &&
      order.size &&
      !shouldStartAdddingByDefaultOnSizeChange.current
    ) {
      let currentCartItems = [editCartItem];
      if (isPackage) {
        const itemsWithItemAsModifiers = editCartItem.modifiers.filter(
          (item) => item.sub_item_required_modifiers.length > 0,
        );
        currentCartItems = itemsWithItemAsModifiers;
      }
      currentCartItems.forEach((currentCartItem) => {
        const groupedData: { modifier_group_id: number; items: any }[] =
          Object.values(
            currentCartItem?.sub_item_required_modifiers.reduce(
              (result, item) => {
                const { modifier_group_id } = item;
                if (!result[modifier_group_id]) {
                  result[modifier_group_id] = {
                    modifier_group_id,
                    items: [],
                  };
                }
                result[modifier_group_id].items.push(item);
                return result;
              },
              {},
            ),
          );

        for (let index = 0; index < groupedData.length; index++) {
          const selectedItems = [];
          let parentIncrementCount = 0;
          const base: {
            price: number;
            size_one_base: number;
            size_two_base: number;
          } = {
            price: 0,
            size_one_base: 0,
            size_two_base: 0,
          };
          const byDefaultSelectedItems = [];
          groupedData[index].items.map((item) => {
            const quantity = _clonedeep(item.quantity);
            if (!parentIncrementCount) {
              parentIncrementCount = item.item_counter_increment;
              base.price = item[mapSizesKey.basePrice[order.size]];
              base.size_one_base = item.size_one_base;
              base.size_two_base = item.size_two_base;
            }
            if (
              quantity > 0 &&
              verifyStatus(item, CONSTANT.MODIFIER_LOCATION)
            ) {
              const currentItemWithNewProperty = {
                ...item,
                id: item.modifier_id,
                quantity: item.item_counter_increment,
                isSelected: true,
              };

              let iterations = 0;
              if (
                item.is_selected_with_count &&
                item[mapSizesKey[order.size]] > 0
              ) {
                // its default one
                // if (item.code === CONSTANT.ADD) {
                //   iterations = quantity / item.item_counter_increment;
                // }
                // if (item.code === CONSTANT.NO) {
                //   iterations = item.item_counter_increment / quantity;
                // }
                if (quantity <= item.item_counter_increment) {
                  iterations = item.item_counter_increment / quantity;
                } else {
                  iterations = quantity / item.item_counter_increment;
                }
                currentItemWithNewProperty.isByDefault = true;
              } else {
                // not default
                iterations = quantity / item.item_counter_increment;
              }
              for (let j = 0; j < iterations; j++) {
                selectedItems.push({ ...currentItemWithNewProperty });
              }
            }
          });

          const itemsWithAdditionalPrice = addAdditionalPrice({
            items: selectedItems,
            currentItemsGroup: {
              id: groupedData[index].modifier_group_id,
              item_counter_increment: parentIncrementCount,

              size_one_base: base.size_one_base,
              size_two_base: base.size_two_base,
            },
            order,
          });

          currentlySelectedItemsAsModifier.push({
            id: groupedData[index].modifier_group_id,
            base: base.price,
            parentIncrementCount,
            selectedItems: itemsWithAdditionalPrice,
            byDefaultSelectedItems: [
              ...selectedItems.filter((item) => item.isByDefault),
            ],
            parentItem: currentCartItem,
          });
        }
      });

      setItemsAsModifier(currentlySelectedItemsAsModifier);
      calculateAndUpdateTotalAdditionalPrice(
        currentlySelectedItemsAsModifier,
        '+',
        dispatch,
      );
      shouldStartAdddingByDefaultOnSizeChange.current = true;
    }
  }, [editCartItem, order.size]);

  const cleanItemAsModifiersPrice = () => {
    const extraPrice: number =
      calculateAdditionalPriceForItemsAsModifiers(itemsAsModifier);
    dispatch(
      addItemAsModifiersPriceInTotalPrice({
        extraPrice,
        extraPriceAction: '-',
      }),
    );
  };

  const addItemAsModifiersPrice = (items) => {
    const extraPrice: number =
      calculateAdditionalPriceForItemsAsModifiers(items);
    dispatch(
      addItemAsModifiersPriceInTotalPrice({
        extraPrice,
        extraPriceAction: '+',
      }),
    );
  };

  const IsAllItemInModifierSelected = ({ item, isCustomizeSectionExposed }) => {
    const mapSizesKey = {
      [CONSTANT.HALF_SIZE.id]: {
        countKey: 'half_item_count',
        selectedItemsKey: 'items_size_one',
      },
      [CONSTANT.FULL_SIZE.id]: {
        countKey: 'full_item_count',
        selectedItemsKey: 'items_size_two',
      },
    };

    // eslint-disable-next-line prefer-const
    let statusOfRequiredInfo = [];
    for (
      let index = 0;
      index < item.sub_items_required_modifier_groups.length;
      index++
    ) {
      const itemAsModifiersData: any =
        item.sub_items_required_modifier_groups[index];
      const requiredSelectionOfItemsAsModifier: number =
        itemAsModifiersData[mapSizesKey[order.size].countKey];
      if (isCustomizeSectionExposed) {
        const selectedItems = itemsAsModifier
          .find((item) => item.id === itemAsModifiersData.id)
          .selectedItems.reduce((acc, item) => acc + item.quantity, 0);
        statusOfRequiredInfo.push(
          requiredSelectionOfItemsAsModifier === selectedItems,
        );
      } else {
        const byDefaultAddedItems =
          itemAsModifiersData[mapSizesKey[order.size].selectedItemsKey].reduce(
            (acc, item) => acc + item.item_count,
            0,
          ) * itemAsModifiersData.item_counter_increment;
        statusOfRequiredInfo.push(
          byDefaultAddedItems === requiredSelectionOfItemsAsModifier,
        );
      }
    }
    const result: { status: boolean; highLightSection: number } = {
      status: true,
      highLightSection: null,
    };
    for (let j = 0; j < item.sub_items_required_modifier_groups.length; j++) {
      if (!statusOfRequiredInfo[j]) {
        result.status = false;
        result.highLightSection = item.sub_items_required_modifier_groups[j].id;
        break;
      }
    }
    return result;
  };

  const addByDefaultItems = (subItemsRequiredModifierGroups, parentItem) => {
    const shouldStartAddiingByDefault = isMobile
      ? (subItemsRequiredModifierGroups?.length > 0 &&
          itemsAsModifier?.length <= 0) ||
        isItemAsModifierCleaningDoneOnMobileView
      : subItemsRequiredModifierGroups?.length > 0;
    if (shouldStartAddiingByDefault && !isMobile) {
      calculateAndUpdateTotalAdditionalPrice(itemsAsModifier, '-', dispatch);
    }
    if (shouldStartAddiingByDefault) {
      for (
        let index = 0;
        index < subItemsRequiredModifierGroups.length;
        index++
      ) {
        byDefaultSeletedItems({
          currentItemGroup: subItemsRequiredModifierGroups[index],
          items: subItemsRequiredModifierGroups,
          mapSizesKey,
          order,
          currentlySelectedItemsAsModifier,
          parentItem,
        });
      }
      setItemsAsModifier(currentlySelectedItemsAsModifier);
    }
  };

  return {
    cleanItemAsModifiersPrice,
    addItemAsModifiersPrice,
    IsAllItemInModifierSelected,
  };
};

const useScrollToMissingRequiredArea = (item, manualSelectedItem) => {
  const [
    requiredModifiersRefsForScrollToMissingSelectedGroupInCYOI,
    setRequiredModifiersRefsForScrollToMissingSelectedGroupInCYOI,
  ] = useState([]);

  const [
    requiredModifiersRefsForScrollToMissingSelectedGroupInItemDetailSection,
    setRequiredModifiersRefsForScrollToMissingSelectedGroupInItemDetailSection,
  ] = useState([]);

  const [
    subItemRequiredModifiersRefsForScrollToMissingSelectedGroupInItemDetailSection,
    setSubItemRequiredModifiersRefsForScrollToMissingSelectedGroupInItemDetailSection,
  ] = useState([]);

  const [
    requiredModifiersRefsForScrollToMissingDynamicSelectedItemForPackagesItemDetailSection,
    setRequiredModifiersRefsForScrollToMissingDynamicSelectedItemForPackagesItemDetailSection,
  ] = useState([]);

  const [
    requiredItemRefsForScrollToMissingDynamicSelectedItemForPackagesItemDetailSection,
    setRequiredItemRefsForScrollToMissingDynamicSelectedItemForPackagesItemDetailSection,
  ] = useState([]);

  const [
    itemAsModifiersRefsForScrollToMissingSelectedGroup,
    setItemAsModifiersRefsForScrollToMissingSelectedGroup,
  ] = useState({});

  const [isCYOIRequiredModifierSelected, setisCYOIRequiredModifierSelected] =
    useState({ status: false, highLightArea: null });

  const [isItemAsModifierSelected, setIsItemAsModifierSelected] = useState({
    status: false,
    highLightArea: null,
  });

  const [
    isRequiredModifiersAtItemDetailSectionSelected,
    setIsRequiredModifiersAtItemDetailSectionSelected,
  ] = useState({
    status: false,
    highLightArea: null,
  });

  const [
    isRequiredModifiersForDynamicSelectedItemForPackagesAtItemDetailSectionSelected,
    setIsRequiredModifiersForDynamicSelectedItemForPackagesAtItemDetailSectionSelected,
  ] = useState({
    status: false,
    highLightArea: null,
  });

  const [
    isRequiredItemsForDynamicSelectedItemForPackagesAtItemDetailSectionSelected,
    setIsRequiredItemsForDynamicSelectedItemForPackagesAtItemDetailSectionSelected,
  ] = useState({
    status: false,
    highLightArea: null,
  });

  const [
    isSubItemRequiredModifiersAtItemDetailSectionSelected,
    setIsSubItemRequiredModifiersAtItemDetailSectionSelected,
  ] = useState({
    status: false,
    highLightArea: null,
  });

  useEffect(() => {
    let requiredTrayItems = null;
    let byDefaultTrayItems = [];

    if (item) {
      assignRefForScroll({
        requiredSection: item?.items_required_modifier_groups,
        refState: requiredModifiersRefsForScrollToMissingSelectedGroupInCYOI,
        setRefState:
          setRequiredModifiersRefsForScrollToMissingSelectedGroupInCYOI,
      });
    }

    if (item && item?.package_categories) {
      requiredTrayItems = item.package_categories.filter(
        (tray: ISubCategories) => tray.items.length > 1,
      );
      if (!requiredTrayItems.length) {
        requiredTrayItems = item.package_categories
          .filter((tray: ISubCategories) => tray.items.length === 1)
          .filter(
            (filteredTray) =>
              filteredTray.items[0].sub_items_required_modifier_groups
                .length === 0,
          );
      }
      byDefaultTrayItems = item.package_categories
        .filter((tray: ISubCategories) => tray.items.length === 1)
        .filter(
          (filteredTray) =>
            filteredTray.items[0].sub_items_required_modifier_groups.length > 0,
        );
    }

    if (item && item?.package_categories && byDefaultTrayItems.length > 0) {
      for (let index = 0; index < byDefaultTrayItems.length; index++) {
        assignRefForScrollUsingObjects({
          requiredSection:
            byDefaultTrayItems[index].items[0]
              ?.sub_items_required_modifier_groups,
          refState: itemAsModifiersRefsForScrollToMissingSelectedGroup,
          setRefState: setItemAsModifiersRefsForScrollToMissingSelectedGroup,
        });
      }
    }

    if (item && item?.sub_items_required_modifier_groups?.length > 0) {
      assignRefForScrollUsingObjects({
        requiredSection: item?.sub_items_required_modifier_groups,
        refState: itemAsModifiersRefsForScrollToMissingSelectedGroup,
        setRefState: setItemAsModifiersRefsForScrollToMissingSelectedGroup,
      });
    }

    if (item && item?.items_required_modifier_groups.length > 0) {
      assignRefForScroll({
        requiredSection: item?.items_required_modifier_groups,
        refState:
          requiredModifiersRefsForScrollToMissingSelectedGroupInItemDetailSection,
        setRefState:
          setRequiredModifiersRefsForScrollToMissingSelectedGroupInItemDetailSection,
      });
    }

    if (item && item.is_package) {
      const filteredTrays: any = item.package_categories
        .filter(
          (tray: ISubCategories) =>
            tray.items.length === 1 &&
            tray.items[0].items_required_modifier_groups.length > 0,
        )
        .map((tray) => tray.items[0]);
      assignRefForScroll({
        requiredSection: filteredTrays,
        refState:
          subItemRequiredModifiersRefsForScrollToMissingSelectedGroupInItemDetailSection,
        setRefState:
          setSubItemRequiredModifiersRefsForScrollToMissingSelectedGroupInItemDetailSection,
      });
    }

    if (item && requiredTrayItems?.length > 0) {
      assignRefForScroll({
        requiredSection: requiredTrayItems,
        refState:
          requiredItemRefsForScrollToMissingDynamicSelectedItemForPackagesItemDetailSection,
        setRefState:
          setRequiredItemRefsForScrollToMissingDynamicSelectedItemForPackagesItemDetailSection,
      });
    }
  }, [item]);

  useEffect(() => {
    if (item && item.is_package && manualSelectedItem) {
      assignRefForScroll({
        requiredSection: item?.items_required_modifier_groups,
        refState:
          requiredModifiersRefsForScrollToMissingDynamicSelectedItemForPackagesItemDetailSection,
        setRefState:
          setRequiredModifiersRefsForScrollToMissingDynamicSelectedItemForPackagesItemDetailSection,
      });
    }
  }, [item, manualSelectedItem]);

  function assignRefForScroll({ requiredSection, refState, setRefState }) {
    const divRefsNew = refState;
    divRefsNew.length = requiredSection?.length;
    // Initialize the refs
    for (let i = 0; i < divRefsNew.length; i++) {
      divRefsNew[i] = divRefsNew[i] || React.createRef();
    }
    setRefState(divRefsNew);
  }

  function assignRefForScrollUsingObjects({
    requiredSection,
    refState,
    setRefState,
  }) {
    const divRefsNew = refState;
    divRefsNew.length = requiredSection?.length;
    // Initialize the refs
    for (let i = 0; i < divRefsNew.length; i++) {
      divRefsNew[requiredSection[i].id] = divRefsNew[i] || React.createRef();
    }
    setRefState(divRefsNew);
  }

  return {
    refToScrollToMissingRequiredArea:
      requiredModifiersRefsForScrollToMissingSelectedGroupInCYOI,
    isCYOIRequiredModifierSelected,
    setisCYOIRequiredModifierSelected,
    itemsAsModifiersToHandleScroll: {
      refToScrollToMissingRequiredItemAsModifiers:
        itemAsModifiersRefsForScrollToMissingSelectedGroup,
      isItemAsModifierSelected,
      setIsItemAsModifierSelected,
    },
    requiredModifiersAtItemDetailSectionToHandleScroll: {
      refToScrollToMissingRequiredModifiers:
        requiredModifiersRefsForScrollToMissingSelectedGroupInItemDetailSection,
      isRequiredModifierSelected:
        isRequiredModifiersAtItemDetailSectionSelected,
      setIsRequiredModifierSelected:
        setIsRequiredModifiersAtItemDetailSectionSelected,
    },
    subItemRequiredModifiersAtItemDetailSectionToHandleScroll: {
      refToScrollToMissingRequiredModifiers:
        subItemRequiredModifiersRefsForScrollToMissingSelectedGroupInItemDetailSection,
      isRequiredModifierSelected:
        isSubItemRequiredModifiersAtItemDetailSectionSelected,
      setIsRequiredModifierSelected:
        setIsSubItemRequiredModifiersAtItemDetailSectionSelected,
    },
    requiredModifiersForDynamicSelectedItemAtItemDetailSectionToHandleScroll: {
      refToScrollToMissingRequiredModifiers:
        requiredModifiersRefsForScrollToMissingDynamicSelectedItemForPackagesItemDetailSection,
      isRequiredModifierSelected:
        isRequiredModifiersForDynamicSelectedItemForPackagesAtItemDetailSectionSelected,
      setIsRequiredModifierSelected:
        setIsRequiredModifiersForDynamicSelectedItemForPackagesAtItemDetailSectionSelected,
    },
    requiredItemForDynamicSelectedItemAtItemDetailSectionToHandleScroll: {
      refToScrollToMissingRequiredModifiers:
        requiredItemRefsForScrollToMissingDynamicSelectedItemForPackagesItemDetailSection,
      isRequiredModifierSelected:
        isRequiredItemsForDynamicSelectedItemForPackagesAtItemDetailSectionSelected,
      setIsRequiredModifierSelected:
        setIsRequiredItemsForDynamicSelectedItemForPackagesAtItemDetailSectionSelected,
    },
  };
};

export default OtherManuItems;
