import { Formik } from 'formik';
import { useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';
import { useHistory } from 'react-router';
import {
  ASAP_TYPE,
  DELIVERY_ORDER_TYPE,
  NO_TIME_SLOTS_PRESENT,
  PICK_UP_METHOD_CURBSIDE,
  ROUTE_CHECKOUT,
  SELECTED_STORE,
} from '../../../../constants';
import {
  setShowCart,
  updateOrderDetails,
} from '../../../../redux/slices/cartSlice';
import { useAppDispatch, useAppSelector } from '../../../../redux/store/store';

// import styleClasses from '../../../../../src/components/ItemGrid/ItemGrid.module.scss';

import Button from 'src/components/Button/Button';
import FormField from 'src/components/FormFields/FormField';
import { brazeLogCustomEventCheckoutStarted } from 'src/helper/brazeHelper';
import { timeSlotPayload } from 'src/helper/checkout/PlaceOrderHelper';
import { formatTime } from 'src/helper/utils';
import useCheckMobileScreen from 'src/hooks/useCheckMobileScreen';

import { CalenderIcon } from 'src/assets/images/Svgs/svg';
import useAddPickupSlotMethod from 'src/react-query-hooks/usePickupSlotMethod';
import useTimeZone from 'src/react-query-hooks/useTimeZone';
import { selectPickupSlotSchema } from 'src/validationSchemas/selectPickUpSlotSchema';
import { ISelectInputState } from '../../../../models/forms.model';
import { bugSnagNotifyAPIError } from 'src/helper/bugSnagHelper';

interface PickUpSlotsProps {
  subTotal?: number;
  tax?: number;
  total?: number;
  isShownVehicleDetails?: (boolean) => void;
  closePickUpMethodModal?: (boolean) => void;
  closeDeliveryMethodModal?: (boolean) => void;
  pickupMethod?: string;
  isEditSlot?: boolean;
}

const PickUpSlots = (props: PickUpSlotsProps) => {
  const {
    tax,
    subTotal,
    total,
    isShownVehicleDetails,
    closePickUpMethodModal,
    closeDeliveryMethodModal,
    pickupMethod,
    isEditSlot,
  } = props;

  let queryClient = useQueryClient();
  let { mutateAsync: addPickupSlotMethod, isLoading: isFetchingSlots } =
    useAddPickupSlotMethod();
  const isItMobile = useCheckMobileScreen();
  const [timeSlotValidation, setTimeSlotValidation] = useState<string>('');
  const [disableContinue, setDisableContinue] = useState<boolean>(true);
  const [handleContinueButton, setHandleContinueButton] =
    useState<boolean>(true);
  const [asapMinutes, setAsapMinutes] = useState<string>(null);
  const { user: authInfo } = useAppSelector((state) => state.user);
  const primaryCapacity = useAppSelector(
    (state) => state.specificFlowStates.primaryCapacity,
  );
  const [dates, setDates] = useState({
    startDate: '',
    lastDate: '',
  });
  const [availablePickUpSlots, setAvailablePickUpSlots] = useState({
    availableTimeSlot: [],
    autoSlectedTimeSlot: '',
  });
  const cart = useAppSelector((state) => state.cart);
  const selectedPickupSlots = cart?.orderDetails?.PickupSlotDetails || '';
  const [pickupSlotValue, setPickupSlotValue] = useState(
    isEditSlot ? selectedPickupSlots?.time : '',
  );
  const [stateOfSlotsFetching, setStateOfSlotsFetching] =
    useState<ISelectInputState>({ state: false, text: null });

  const history = useHistory();
  const locationInfo = useAppSelector((state) => state.location);
  const orderTypeDispatch = useAppDispatch();
  const orderDetailsDeliveryAddress =
    cart?.orderDetails?.delivery_address || '';
  const vehicleDetails = cart?.orderDetails?.vehicleDetails || '';
  const orderType = cart?.orderType || '';
  const alreadySelectedDate = new Date(selectedPickupSlots?.date);
  const selectedLocationTimezone = JSON.parse(
    localStorage.getItem(SELECTED_STORE),
  )?.time_zone;
  const { data: dateTime, isFetching: isFetchingDateTime } = useTimeZone({
    timeZone: selectedLocationTimezone || '',
  });
  const currentDate = dateTime?.split(' ')?.[0];
  const currentDateTime = new Date(currentDate);
  const primaryDateTime = new Date(primaryCapacity.date);
  const isSelectDateInPast = alreadySelectedDate < currentDateTime;
  const isPrimarySelectedDateInPast = primaryDateTime < currentDateTime;
  const isApiFetching = isFetchingDateTime || isFetchingSlots;
  useEffect(() => {
    if (!selectedPickupSlots?.time?.length) {
      addPickUpSlotsToCart(null);
    }
  }, [availablePickUpSlots]);
  useEffect(() => {
    if ((isSelectDateInPast && !isEditSlot) || !calenderDate) {
      setCalenderDate(currentDate ?? '');
      const pickupSlotObj = {
        date: currentDate,
        time: '',
        pickupMethod:
          orderType == DELIVERY_ORDER_TYPE ? DELIVERY_ORDER_TYPE : pickupMethod,
        isAsapOrder: false,
        asapMinutes: null,
      };
      orderTypeDispatch(
        updateOrderDetails({
          delivery_address: orderDetailsDeliveryAddress,
          vehicleDetails: vehicleDetails,
          PickupSlotDetails: pickupSlotObj,
        }),
      );
    }
  }, [dateTime]);
  const [calenderDate, setCalenderDate] = useState(
    selectedPickupSlots
      ? selectedPickupSlots?.date
      : isPrimarySelectedDateInPast
      ? currentDate
      : primaryCapacity.date,
  );
  let initialFormState;
  initialFormState = {
    selected_slot: selectedPickupSlots?.time
      ? availablePickUpSlots?.availableTimeSlot.filter((slot) => {
          return slot.value === selectedPickupSlots.time;
        }).length
        ? {
            label: formatTime(selectedPickupSlots.time, {
              time: selectedPickupSlots.time,
            }),
            value: selectedPickupSlots.time,
          }
        : { label: 'Select Time', value: null }
      : availablePickUpSlots?.availableTimeSlot,
  };

  useEffect(() => {
    let startDate = currentDate;
    let lastDate = getLastDate();
    if (startDate && lastDate) {
      setDates({
        startDate: startDate,
        lastDate: lastDate,
      });

      if (!selectedPickupSlots && dates) {
        addPickUpSlotsToCart(null);
      }
    }
  }, [dates.startDate, dates.lastDate, dateTime]);

  useEffect(() => {
    if (dates.startDate) {
      handlePickUpSlots(
        selectedPickupSlots ? selectedPickupSlots.date : dates.startDate,
      );
    }
  }, [dates.startDate, dateTime]);

  const getLastDate = () => {
    const week = currentDateTime;
    const updated_date = week.setDate(week.getDate() + 180);
    const dateAfterWeek = new Date(updated_date);
    const day = dateAfterWeek.getUTCDate();
    const month = dateAfterWeek.getUTCMonth() + 1;
    const year = dateAfterWeek.getUTCFullYear();
    const lastdateStr =
      year + '-' + ('0' + month).slice(-2) + '-' + ('0' + day).slice(-2);

    return lastdateStr;
  };

  const handleCalenderChange = (value: any) => {
    setCalenderDate(value);
    handlePickUpSlots(value);
  };

  const addPickUpSlotsToCart = (values) => {
    let pickupSlotObj = {
      date: calenderDate ? calenderDate : dates?.startDate,
      time:
        values?.selected_slot?.value ||
        (availablePickUpSlots.autoSlectedTimeSlot
          ? availablePickUpSlots.autoSlectedTimeSlot
          : selectedPickupSlots.time || ''),
      pickupMethod:
        orderType == DELIVERY_ORDER_TYPE ? DELIVERY_ORDER_TYPE : pickupMethod,
      isAsapOrder: pickupSlotValue
        ? pickupSlotValue?.includes(ASAP_TYPE)
        : availablePickUpSlots?.autoSlectedTimeSlot?.includes(ASAP_TYPE),
      asapMinutes: asapMinutes,
    };
    orderTypeDispatch(
      updateOrderDetails({
        delivery_address: orderDetailsDeliveryAddress,
        PickupSlotDetails: pickupSlotObj,
        vehicleDetails: vehicleDetails,
      }),
    );
    return pickupSlotObj;
  };

  const handleFormSubmission = async (values) => {
    const timeSlotObject = addPickUpSlotsToCart(values);
    handleNextButton(timeSlotObject);
  };

  const toggleContinueButton = (value) => {
    if (value) {
      setTimeSlotValidation('No Time Slots Available');
      setDisableContinue(true);
    } else {
      setTimeSlotValidation('');
      setDisableContinue(false);
    }
  };

  const byDefaultSelectInputState = () => {
    setStateOfSlotsFetching({ state: true, text: 'No Option' });
  };

  const handlePickUpSlots = async (value: any) => {
    setStateOfSlotsFetching({ state: true, text: 'Loading...' });
    setHandleContinueButton(true);

    let modifiedValues = {
      location_id: locationInfo.selectedStore.id.toString(),
      date: value || dates?.startDate,
      customer_id: authInfo.id,
      catering: 1,
      ...timeSlotPayload(cart),
    };
    let mutate = addPickupSlotMethod;
    return mutate(
      {
        newPickupSlotMethod: modifiedValues,
      },
      {
        onSuccess: (data, variables, context) => {
          if (
            (data?.available_slots != '' &&
              data?.available_slots != NO_TIME_SLOTS_PRESENT) ||
            data?.asap_time
          ) {
            byDefaultSelectInputState();
            if (data?.asap_time) {
              setAsapMinutes(data.asap_time);
              data?.available_slots.unshift(
                'ASAP: ' + data?.asap_time + ' minutes',
              );
            }
            toggleContinueButton(false);
            if (selectedPickupSlots.time) {
              if (
                !data?.available_slots.filter(
                  (slot) => slot === selectedPickupSlots.time,
                ).length
              ) {
                setTimeSlotValidation(
                  'The time you selected is not available anymore. Please select a different time slot for your order',
                );
                setDisableContinue(true);
              }
            }
            setAvailablePickUpSlots({
              availableTimeSlot: data?.available_slots?.map((item) => {
                return {
                  label: formatTime(item, { time: item }),
                  value: item,
                };
              }),
              autoSlectedTimeSlot: data?.available_slots[0],
            });
            queryClient.invalidateQueries('pickupSlotMethod');
          } else {
            setAvailablePickUpSlots({
              availableTimeSlot: [],
              autoSlectedTimeSlot: NO_TIME_SLOTS_PRESENT,
            });
            setStateOfSlotsFetching({
              state: true,
              text: 'No Time Slots Available!',
            });
            toggleContinueButton(true);
          }
        },
        onError: (error: any, variables, context) => {
          setAvailablePickUpSlots({
            availableTimeSlot: [],
            autoSlectedTimeSlot: NO_TIME_SLOTS_PRESENT,
          });
          setStateOfSlotsFetching({
            state: true,
            text: 'No Time Slots Available!',
          });
          toggleContinueButton(true);
          bugSnagNotifyAPIError(
            'Available slots',
            modifiedValues,
            error?.response?.data?.message,
          );
        },
      },
    );
  };

  const handleNextButton = async (slotDetails) => {
    // queryClient.refetchQueries(["get-cart", selectedLocation.id]);
    // if (timeSlotValidation == '') {
    if (timeSlotValidation == '') {
      if (orderType == DELIVERY_ORDER_TYPE) {
        //Case: Delievery Method
        closeDeliveryMethodModal(true);
        orderTypeDispatch(setShowCart(false));
        brazeLogCustomEventCheckoutStarted(
          cart,
          { discount: cart?.discount, tax, subTotal, total },
          isItMobile,
          slotDetails,
          locationInfo.selectedStore,
        );

        history.push(ROUTE_CHECKOUT);
      } else {
        if (pickupMethod === PICK_UP_METHOD_CURBSIDE) {
          // Case: CurbSide Pickup Method
          isShownVehicleDetails(true);
        } else {
          //Case: InStore Pickup Method

          closePickUpMethodModal(true);
          orderTypeDispatch(setShowCart(false));
          brazeLogCustomEventCheckoutStarted(
            cart,
            { discount: cart.discount, tax, subTotal, total },
            isItMobile,
            slotDetails,
            locationInfo.selectedStore,
          );

          history.push(ROUTE_CHECKOUT);
        }
      }
    } else {
      setAvailablePickUpSlots({
        availableTimeSlot: [],
        autoSlectedTimeSlot: NO_TIME_SLOTS_PRESENT,
      });
      toggleContinueButton(true);
    }
  };

  return (
    <div className="mt-4">
      <Formik
        initialValues={initialFormState}
        onSubmit={handleFormSubmission}
        validationSchema={selectPickupSlotSchema}
        enableReinitialize
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleSubmit,
          isSubmitting,
          setFieldValue,
          submitForm,
        }) => (
          <>
            <form
              className="new_form_design  delivery_location_select full_width_mob show_datepick_calender"
              onSubmit={handleSubmit}
            >
              <div className="form-group">
                <label className="d-flex justify-content-between">
                  Select Order Date
                </label>

                <div className="position-relative">
                  <input
                    name="date"
                    type="date"
                    required
                    disabled={isApiFetching}
                    min={dates?.startDate}
                    max={dates?.lastDate}
                    value={calenderDate}
                    className={` form-control no-style type-data position-relative`}
                    onKeyDown={(e) => e.preventDefault()}
                    onChange={(e) => {
                      setPickupSlotValue('');
                      handleCalenderChange(e.target.value);
                    }}
                  />
                  <span
                    // className={styleClasses.date_icon_container}
                    data-testid="icon-container "
                  >
                    <CalenderIcon className="calenderIcon pointerNone" />
                  </span>
                </div>
              </div>

              <FormField
                options={availablePickUpSlots.availableTimeSlot}
                type={'select'}
                name="selected_slot"
                labelText={
                  orderType == DELIVERY_ORDER_TYPE
                    ? 'Select a Delivery Time'
                    : 'Select a Pick-Up Time'
                }
                placeholder={
                  timeSlotValidation
                    ? NO_TIME_SLOTS_PRESENT
                    : availablePickUpSlots?.autoSlectedTimeSlot
                    ? formatTime(availablePickUpSlots?.autoSlectedTimeSlot, {
                        time: availablePickUpSlots?.autoSlectedTimeSlot,
                      })
                    : NO_TIME_SLOTS_PRESENT
                }
                errors={errors}
                touched={touched}
                value={
                  timeSlotValidation && timeSlotValidation === ''
                    ? '00:00:00'
                    : values.selected_slot
                }
                onChange={(slot) => {
                  setFieldValue('selected_slot', slot);
                  toggleContinueButton(false);
                  if (slot) {
                    setPickupSlotValue(slot.value);
                  }
                }}
                inputFieldClass={'custom_select mb-0'}
                handleLoading={{
                  enable: stateOfSlotsFetching.state,
                  inputValue: stateOfSlotsFetching.text,
                }}
              />
              <div className="justify-start-center mt-4 mb-4">
                <span className="d-block f-s14 font-Visby-cf f-w5">
                  {locationInfo.selectedStore.name}
                </span>
                <span className="d-block f-s14 font-Visby-cf f-w5">
                  {locationInfo.selectedStore.address}
                </span>
                <span className="d-block f-s14 font-Visby-cf f-w5">
                  {locationInfo.selectedStore.city},{' '}
                  {locationInfo.selectedStore.state}{' '}
                  {locationInfo.selectedStore.zip}
                </span>
              </div>
              {timeSlotValidation && (
                <p className="text-center my-3 f-s14 w-75 mx-auto full_width_mob px-4 clr-dark-red">
                  {timeSlotValidation}
                </p>
              )}

              <Button
                disabled={
                  disableContinue || isApiFetching || !!timeSlotValidation
                }
                loading={
                  handleContinueButton && !timeSlotValidation && isApiFetching
                }
                type="button"
                className="  py-2 f-s16 w-100 my-2 lh-lg"
                onClick={submitForm}
              >
                Continue
              </Button>
            </form>
          </>
        )}
      </Formik>
    </div>
  );
};

export default PickUpSlots;
